import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Carousel, CarouselProps, Image } from "antd";
import { CustomArrowProps } from "@ant-design/react-slick";
import { CarouselRef } from "antd/es/carousel";
import clsx from "clsx";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./styles/component-carousel.less";

interface Props extends CarouselProps, WithTranslation {
    initialSlide?: number;
    className?: string;
    roundDots?: boolean;
    preview?: boolean;
    images?: CarouselImageProps[];
    children?: ReactNode;
}

interface CarouselImageProps {
    src: string;
    onClick?: () => void;
    alt?: string;
    className?: string;
    key?: string | number;
    style?: React.CSSProperties;
}

interface ArrowProps extends CustomArrowProps {
    onSlideChange: (next: boolean) => void;
    label: string;
}

// wrap extra props to prevent react-slick error in console
const ForwardArrow = ({ label, currentSlide, slideCount, onSlideChange, ...props }: ArrowProps): JSX.Element =>
    <IoChevronForward
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
            // enter
            if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                onSlideChange(true);
            }
        }}
        aria-label={label}
        {...props}
    />;
const BackArrow = ({ label, currentSlide, slideCount, onSlideChange, ...props }: ArrowProps): JSX.Element =>
    <IoChevronBack
        role="button"
        tabIndex={currentSlide === slideCount ? -1 : 0}
        onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                onSlideChange(false);
            }
        }}
        aria-label={label}
        {...props}
    />;

const ComponentCarousel: React.FC<Props> = ({ t, className, initialSlide, draggable = true, roundDots, preview, images, children, ...props }: Props) => {

    const carouselRef = useRef<CarouselRef>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [previewIndex, setPreviewIndex] = useState<number | undefined>(undefined);

    useEffect(() => {
        // manually change to initialSlide if provided,
        // default initialSlide doesn't work when changing page
        if (initialSlide && carouselRef.current) {
            carouselRef.current.goTo(initialSlide);
        }
    }, [initialSlide, carouselRef]);

    const handleDragging = useCallback(() => {
        setIsDragging(true)
    }, [setIsDragging]);

    const handleOnClickCapture = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (isDragging) {
            e.stopPropagation();
            e.preventDefault();
            setIsDragging(false);
        }
    }, [isDragging]);

    const handleSlideChange = useCallback((next: boolean) => {
        if (carouselRef.current) {
            if (next) {
                carouselRef.current.next();
            } else {
                carouselRef.current.prev();
            }
        }
    }, [carouselRef]);

    return (
        <>
            <Carousel
                ref={carouselRef}
                className={clsx(styles.carouselCommon, className)}
                dots
                speed={500}
                arrows
                draggable={draggable}
                swipeToSlide
                slidesToShow={1}
                slidesToScroll={1}
                pauseOnHover
                lazyLoad="progressive"
                nextArrow={<ForwardArrow label={t("next")} onSlideChange={handleSlideChange} />}
                prevArrow={<BackArrow label={t("previous")} onSlideChange={handleSlideChange} />}
                onSwipe={draggable ? handleDragging : undefined}
                appendDots={(dots) => (
                    <ul role="tablist" style={{ display: "block" }} onClick={(e) => e.stopPropagation()}>
                        {dots}
                    </ul>
                )}
                {...props}
            >
                {
                    images && images.length > 0
                        ? images.map((i, index) =>
                            <div key={i.key ?? index} onClickCapture={handleOnClickCapture}>
                                <Image onClick={() => setPreviewIndex(preview ? index : undefined)} preview={false} {...i} />
                            </div>
                        )
                        : React.Children.map(children, (child, index) =>
                            <div key={index} onClickCapture={handleOnClickCapture}>
                                { child }
                            </div>
                        )
                }
            </Carousel>

            {
                preview === true && images && images.length > 0 &&
                <div style={{ display: "none" }}>
                    <Image.PreviewGroup preview={{
                        visible: previewIndex !== undefined,
                        current: previewIndex,
                        onVisibleChange: isVisible => setPreviewIndex(isVisible ? 0 : undefined),
                    }}>
                        { images.map((i, index) => <Image key={i.key ?? index} src={i.src} alt={i.alt} />) }
                    </Image.PreviewGroup>
                </div>
            }
        </>
    )
};

export default withTranslation(["ui"])(ComponentCarousel);
