import i18next from '../i18next';

i18next.addResourceBundle('en', 'shared', {
    validationRequired: '{{field}} is required.',
    validationFormat: 'Please enter a valid {{format}}.',
    validationMin: "Must be more than or equal to {{min}}.",
    validationMax: "Must be less than or equal to {{max}}.",
    validationBetween: "Must be between {{min}} and {{max}}.",
    validationMinCharacters: "Must be a minimum of {{min}} characters.",
    validationMaxCharacters: "Must be a maximum of {{max}} characters.",
    number: "number",
    integer: "whole number",
    error: 'Error',
    genericError: 'There has been an error transmitting your request to the server, please try again.',
    criticalError: 'A critical error has occurred, unsaved changes may have been lost. This error has been logged.',
    yes: 'Yes',
    no: 'No',
    reload: 'Reload',
    stringJoiner: ', ',
    plusnMore: "+{{ n }} More",
    fileInvalid: "The file {{file}} is invalid.",
    true: "True",
    false: "False",
    lessThanXY: " < {{ x }}{{ y }}",
    measureImperialShort: "mi",
    measureMetricShort: "km",
    emailTextPlaceholder: "info@resiada.com",
    websiteTextPlaceholder: "https://resiada.com",
    phoneTextPlaceholder: "1-888-737-4232",
    selectAll: "Select All",
    clearAll: "Clear All"
});