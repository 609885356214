import React, { Component } from 'react';
import { Alert } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    message?: string;
}

class EventManager extends Component<Props> {
    render() {
        const { t, message } = this.props;

        return <Alert type="error" message={message ?? t("noPermissionError")} />;
    }
}

export default withTranslation(["security"])(EventManager);