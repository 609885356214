import { Button, Modal, Space, Typography } from "antd";
import React, { Component } from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import TestStatusBar from "menu/test-status-bar";
import { UserModel } from "user";
import { sessionAppStorage } from "state";

const { Title } = Typography;

interface Props extends WithTranslation {
    user: UserModel;
    isTestSite: boolean;
}

interface State {
    isVisible: boolean;
}

const TEST_WARNING_KEY = "showTestSiteWarning";

class TestSiteWarning extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isVisible: (sessionAppStorage.get(TEST_WARNING_KEY) ?? "true") === "true"
        };
    }

    acceptTestWarning = () => {
        sessionAppStorage.set(TEST_WARNING_KEY, "false").save();
    }

    render() {
        const { t, user, isTestSite } = this.props;
        const { isVisible } = this.state;

        const email = user.event && user.event.eventSupportEmail.length > 0 ? user.event.eventSupportEmail : user.branding.displayEmail;
        const phone = user.event && user.event.eventSupportPhone.length > 0 && user.event.eventSupportPhone;

        return (
            isTestSite &&
            <>
                <TestStatusBar />
                <Modal
                    open={isVisible}
                    closable={false}
                    footer={null}
                    destroyOnClose
                    width={590}
                    centered
                >
                    <Title level={2} style={{ marginBottom: 8 }}>{t("welcomeTestSite")}</Title>
                    <Space direction="vertical" size={16}>
                        <Trans i18nKey={t("welcomeTestSiteDescTextOne")} />
                        {
                            phone && phone.length > 0 ?
                                <Trans i18nKey={t("welcomeTestSiteDescTextTwo", { email: email, phone: phone })} components={{ mail: <a href={t("mailToHref", { email })} target="new"> </a>, tel: <a href={t("telHref", { phone })} target="new"></a> }} />
                                :
                                <Trans i18nKey={t("welcomeTestSiteDescTextTwoWithoutPhone", { email: email })} components={{ mail: <a href={t("mailToHref", { email })} target="new"></a> }} />
                        }
                    </Space>
                    <Button type="primary" style={{ width: 160, display: "block", marginLeft: "auto", marginTop: 32 }} size="large" onClick={() => this.setState({ isVisible: false }, this.acceptTestWarning)}>{t("accept")}</Button>
                </Modal>
            </>
        );
    }
}

export default withTranslation("menu")(TestSiteWarning);