// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag__OqoRH {
  background: #B3DFFF;
  border: unset;
  border-radius: 50px;
}
.tag__OqoRH .tagClose__Y08wh svg {
  transform: scale(1.5);
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/filters/components/styles/filter-list-select.module.less"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAFA;EAMQ,qBAAA;AADR","sourcesContent":["@import \"../../../../../apps/styles/style-variables.less\";\n\n.tag {\n    background: @primaryColorLight;\n    border: unset;\n    border-radius: @cornerRoundTag;\n\n    .tagClose svg {\n        transform: scale(1.5);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `tag__OqoRH`,
	"tagClose": `tagClose__Y08wh`
};
export default ___CSS_LOADER_EXPORT___;
