import "core-js"; //Polyfill for most things
import "regenerator-runtime/runtime"; //Polyfill for async/await
import 'whatwg-fetch'; //Polyfill for ancient web browsers like IE.
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch' //Fetch AbortController Polyfill
import FusionCharts from "fusioncharts";

//Move to a better place when we consolidate apps.
//@ts-ignore FusionCharts doesn't want to include their license function on the typings for some reason.
FusionCharts.options.license({
    key: "wkB3gzpI2J3A4A6D5E4C4G4C3I3G1A7C8wfhC4D1aieynD6D3B-8kdG2C9A4D6C5G5E4G2C3F3B4C5A4B1A2B4snnB14B1D8fB4A4H4H-8I-7iA7A3A3wqh1H2C1C5B-22moD2D5F1G3D2A6B5A4C7A3B4h1jjB8C4QB5jnB-8H3F1B5tgB2HA1E2A-7H-7nA2D6E1A3D3B2B1C2A5B3A5A2E2B3t==",
    creditLabel: false,
});
