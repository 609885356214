// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date__EQRmG {
  border-radius: 0;
  border: none;
  padding: 5px;
  width: 100%;
  box-shadow: none;
}
.date__EQRmG input {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/date-picker/styles/date-picker-editor.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AADJ;AAJA;EAQQ,eAAA;AADR","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.date {\n    border-radius: 0;\n    border: none;\n    padding: @agPadding;\n    width: 100%;\n    box-shadow: none;\n\n    input {\n        font-size: @agFontSize;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `date__EQRmG`
};
export default ___CSS_LOADER_EXPORT___;
