import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { SystemMessage, SystemMessageStyle } from 'web-service';
import sanitizeHtml from 'sanitize-html';
import { acceptedHtmlOptions, ComponentCarousel } from 'ui';
import { IoClose } from 'react-icons/io5';
import clsx from "clsx";
import styles from './styles/system-message-item.module.less';
import { BannerStatus } from "web-service/system-message";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
    message: SystemMessage;
    onClose?: (style: SystemMessageStyle, id?: string) => void;
    remaining?: number;
}

class SystemMessageItem extends Component<Props> {

    render() {
        const { t, message, onClose, remaining } = this.props;

        return (
            <div>
                {
                    message.systemMessageStyle === SystemMessageStyle.Popup &&
                    <div className="inner-message-container">
                        <h3>{message.header}</h3>
                        <Row gutter={[12, 48]} className={styles.messageCarousel} align="middle">
                            <Col xs={24} sm={message.imageURLs.length > 0 ? 12 : 24}>
                                <div className={clsx(styles.carouselMessage, styles.wysiwygContent, {
                                    [styles.hasImage]: message.imageURLs.length > 0
                                })}>
                                    <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(message.notificationMessage, acceptedHtmlOptions) }} />
                                    <div className={styles.buttonLink}>
                                        {!!message.buttonText && !!message.buttonLink && <Button shape="round" className={styles.topBannerLinkButton} href={message.buttonLink} target='_blank'>{message.buttonText}</Button>}
                                    </div>
                                </div>
                            </Col>
                            {
                                message.imageURLs.length > 0 &&
                                <Col xs={24} sm={12}>
                                    <ComponentCarousel
                                        className={styles.imageCarousel}
                                        draggable
                                        dots
                                        arrows={false}
                                        images={message.imageURLs.map((url, i) => ({ key: i, src: url, style: { objectFit: "contain" } }))}
                                    />
                                </Col>
                            }
                        </Row>
                    </div>
                }
                {
                    [SystemMessageStyle.Banner, SystemMessageStyle.LoginBanner].includes(message.systemMessageStyle) &&
                    <div
                        className={clsx(styles.topBanner, {
                            [styles.informational]: message.bannerStatus === BannerStatus.Informational,
                            [styles.critical]: message.bannerStatus === BannerStatus.Critical,
                            [styles.success]: message.bannerStatus === BannerStatus.Success,
                            [styles.important]: message.bannerStatus === BannerStatus.Important,
                        })}
                    >
                        <Row wrap={false} gutter={20} align="middle">
                            <Col flex="1 1 0">
                                <strong>{message.header}</strong>
                                <p style={{ marginBottom: 0 }}>{message.notificationMessage}</p>
                            </Col>
                            {
                                !!message.buttonText && !!message.buttonLink &&
                                <Col>
                                    <Button className={styles.topBannerLinkButton} shape="round" href={message.buttonLink} target='_blank'>{message.buttonText}</Button>
                                </Col>
                            }
                            {
                                remaining !== undefined && remaining > 0 &&
                                <Col>
                                    <strong>{t("nRemaining", { n: remaining })}</strong>
                                </Col>
                            }
                            <Col>
                                <Button className={styles.topBannerCloseButton} onClick={() => onClose?.(message.systemMessageStyle, message.id)} type="text">
                                    <IoClose />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    message.systemMessageStyle === SystemMessageStyle.Login &&
                    <div className={styles.messageLogin}>
                        {
                            message.imageURLs.length > 0 &&
                            <ComponentCarousel
                                draggable
                                dots
                                arrows={false}
                                images={message.imageURLs.map((url, i) => ({ key: i, src: url, style: { objectFit: "contain" } }))}
                                className={styles.imageCarousel}
                            />
                        }
                        <div
                            className={clsx(styles.carouselMessage, {
                                [styles.hasImage]: message.imageURLs.length > 0
                            })}
                        >
                            <h2>{message.header}</h2>
                            <div className={styles.wysiwygContent}>
                                <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(message.notificationMessage, acceptedHtmlOptions) }} />
                            </div>
                            {!!message.buttonText && !!message.buttonLink && <Button className={styles.topBannerLinkButton} shape="round" href={message.buttonLink} target='_blank'>{message.buttonText}</Button>}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation("menu")(SystemMessageItem);