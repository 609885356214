import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, List, Row } from 'antd';
import { UserModel } from 'user';
import pageList from '../data/page-list';
import styles from './styles/manage-pane.less';
import SearchItem from '../data/search-item';

interface Props extends WithTranslation {
    user: UserModel;
    category?: string;
}

interface State {
    pages: Array<SearchItem>;
}

class ManagePane extends Component<Props, State> {

    constructor(props : Props) {
        super(props);

        this.state = {
            pages: pageList(props.user, props.t)
        };
    }


    layoutItems = (array: Array<SearchItem>, t : TFunction, user : UserModel, limit? : string) => {
        let result : any = {};

        for (let i = 0; i < array.length; i++) {
            var category = array[i].description;           

            if (category == t('event') || category == t('advanced')) {

                if (result[category] == undefined) {
                    result[category] = [];
                }

                result[category].push(<List.Item className={styles.menuListItem} key={i}><a href={user.baseUrl + array[i].id}>{array[i].name}</a></List.Item>)
            }
        }
       
        if (limit != undefined)
            return (<List>{ result[limit] }</List>);

        return Object.keys(result).map(function (key, x) {
            return (<Col span={24} md={12} key={x}>
                <div className={styles.menuList}>
                    <h3 className={styles.menuListTitle}>{key}</h3>
                    <List>
                        {result[key]}
                    </List>
                </div>
            </Col>);
        });
    }

    render() {
        const { t, user, category } = this.props;

        return (
            <Row className={styles.menuListContainer}>
                {this.layoutItems(this.state.pages, t, user, category)}
            </Row>
        );
    }
}

export default withTranslation('menu')(ManagePane);