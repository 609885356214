// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawerList__InY1N [class*=ant-list-item]:first-child {
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/filters/styles/filter-drawer.module.less"],"names":[],"mappings":"AAAA;EAEQ,cAAA;AAAR","sourcesContent":[".drawerList {\n    [class*=ant-list-item]:first-child {\n        padding-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerList": `drawerList__InY1N`
};
export default ___CSS_LOADER_EXPORT___;
