// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box__nbquL {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #F1F1F7;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 76px;
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.04);
}
.box__nbquL div.ant-input-number {
  width: 70px !important;
}
.content__Akp7h {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.content__Akp7h p {
  margin-bottom: 0;
  font-weight: 700;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .content__Akp7h {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .content__Akp7h {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .content__Akp7h span,
  .content__Akp7h button {
    width: 100%;
  }
  .content__Akp7h div.ant-input-number {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/group-highlight/styles/group-highlight.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,8CAAA;AADJ;AATA;EAaY,sBAAA;AADZ;AAMA;EACI,aAAA;EACA,iBAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;AAJJ;AADA;EAQQ,gBAAA;EACA,gBAAA;AAJR;AAQI;EAAA;IACI,WAAA;EALN;AACF;AAOI;EAAA;IACI,WAAA;IACA,sBAAA;IACA,uBAAA;EAJN;EACE;;IAKQ,WAAA;EAFV;EAHE;IAUY,WAAA;EAJd;AACF","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.box {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    background-color: #F1F1F7; //match $ag-chrome-background\n    border: 1px solid #cccccc; //match $ag-border-color\n    border-radius: @agBorderRadius;\n    padding: 20px;\n    margin-bottom: 20px;\n    min-height: 76px; //height of normal button content to match - may have to adjust based off content\n    box-shadow: 0 0px 20px 0px rgba(0,0,0,0.04);\n    :global {\n        div.ant-input-number {\n            width: 70px !important;\n            //allows 888 digit width\n        }\n    }\n}\n.content {\n    display: flex;\n    flex-wrap: nowrap;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 16px;\n\n    p {\n        margin-bottom: 0;\n        font-weight: 700;\n    }\n\n\n    @media screen and (min-width: @screenMD) and (max-width: @screenXL - 1) {\n        width: 100%;\n    }\n\n    @media screen and (max-width: @screenMD) {\n        width: 100%;\n        flex-direction: column;\n        align-items: flex-start;\n        span, button {\n            width: 100%;\n        }\n\n        :global {\n            div.ant-input-number {\n                width: 100%;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `box__nbquL`,
	"content": `content__Akp7h`
};
export default ___CSS_LOADER_EXPORT___;
