import { FileModel } from "ui";
import { CardType } from "web-service";
import { EventLocation } from "../event";

interface HotelModel {
    id?: string;
    translations?: HotelGetTranslation;
    rating?: number;
    headquarters?: boolean;
    firstRoomNight?: string;
    lastRoomNight?: string;
    closingDate?: string;
    committedPercentage?: number;
    excludedCreditCards?: CardType[];
    phone?: string;
    email?: string;
    fax?: string;
    website?: string;
    eventHotelLogoID: string;
    selectedAmenities: string[];
    location?: EventLocation;
    selectedTaxes: string[];
    images?: HotelImageModel[];
    eventHotelLogo: EventHotelLogoModel;
    hotelLogo: number;
    hasReservations: boolean;
    minimumNightsModel?: MinimumNights;
    active: boolean;
    datesRestricted: boolean;
    publicReservationEdit: boolean | null;
    publicReservationCancel: boolean | null;
}

interface HotelGetTranslation {
    name: string;
    description: string;
    cancellationPolicy: string;
    languageID: string;
}

enum TaxLevel {
    PerRoomNight = 0,
    PerReservation = 1
}

enum TaxType {
    Tax = 0,
    HotelFee = 1,
    SubBlockFee = 2
}

enum EventTaxAppliedAt {
    Total = 0,
    Deposit = 1
}

interface EventTax {
    id?: string;
    translation: EventTaxTranslation;
    isPercent: boolean;
    level: TaxLevel;
    amount: number;
    perOccupancy: boolean;
    hotelSpecific: boolean;
    taxType: TaxType;
    appliedTo: EventTaxAppliedAt;
    reservationWarning?: ReservationWarning;
    appliedFeeTaxes?: EventTax[];
}

interface EventTaxTranslation {
    name: string;
    description?: string;
}

interface ReservationWarning {
    entity?: string;
    updateField: string;
    updateReservations?: boolean;
    active?: boolean;
    showCheckbox?: boolean;
}

interface HotelImageModel {
    file: FileModel;
    data: string | null;
    order: number;
}

interface EventHotelLogoModel {
    imageId?: string;
    eventId?: string;
    file: FileModel;
    data: string | null;
    name: string;
    key: number;
    modified: boolean;
    defaultLogo: boolean;
}

interface MinimumNights {
    minimumLength: number;
    minimumRequiredNights: number;
    requiredNights: string[];
}

export { TaxLevel, TaxType, EventTaxAppliedAt };
export type { HotelModel, EventTax, HotelImageModel, ReservationWarning, EventHotelLogoModel };
