// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag__d4AN3 {
  background: #B3DFFF;
  border: unset;
  border-radius: 50px;
  margin: 0;
  display: block !important;
}
.tag__d4AN3 .tagClose__zfgfD svg {
  transform: scale(1.5);
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/filters/components/styles/filter-list-search-select.module.less"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;AADJ;AAJA;EAQQ,qBAAA;AADR","sourcesContent":["@import \"../../../../../apps/styles/style-variables.less\";\n\n.tag {\n    background: @primaryColorLight;\n    border: unset;\n    border-radius: @cornerRoundTag;\n    margin: 0;\n    display: block !important;\n\n    .tagClose svg {\n        transform: scale(1.5);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `tag__d4AN3`,
	"tagClose": `tagClose__zfgfD`
};
export default ___CSS_LOADER_EXPORT___;
