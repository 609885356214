// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleContainer__uKOpy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 6px auto 30px 0;
}
.action__ZebNz {
  margin: 0;
}
.title__R8t01 {
  font-size: 42px;
  font-weight: 600;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/title/styles/title.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,uBAAA;AADJ;AAIA;EACI,SAAA;AAFJ;AAKA;EACI,eAAA;EACA,gBAAA;EACA,SAAA;AAHJ","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.titleContainer {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    margin: (@headerLrPadding/2) auto (@headerLrPadding*2.5) 0;\n}\n\n.action {\n    margin: 0;\n}\n\n.title {\n    font-size: 42px;\n    font-weight: 600;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `titleContainer__uKOpy`,
	"action": `action__ZebNz`,
	"title": `title__R8t01`
};
export default ___CSS_LOADER_EXPORT___;
