import { TFunction } from "i18next";
import { EventStatus } from "web-service";

class Status {

    getStatus(status: EventStatus, t: TFunction) {
        switch (status) {
            case EventStatus.Preview: //Preview
                return t('statusPreview');
            case EventStatus.Demo: //Demo
                return t('statusDemo');
            case EventStatus.Live: //Live
                return t('statusLive');
            case EventStatus.Archived: //Archived
                return t('statusArchived');
        }
    }

    getStatusClass(status: EventStatus, styles: any) {
        switch (status) {
            case EventStatus.Preview: //Preview               
            case EventStatus.Demo: //Demo
                return styles.statusPreview;
            case EventStatus.Live: //Live
                return styles.statusLive;
            case EventStatus.Archived: //Archived
                return styles.statusArchived;
        }
    }
}

export default new Status();