import { Button, Checkbox, Divider, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styles from './styles/checkbox-dropdown-renderer.less';
import { IoChevronDown } from "react-icons/io5";
import Icon from "@ant-design/icons";

interface Props extends WithTranslation {
    options: { key: string, value: string }[];
    value?: string[],
    onChange?: (values: string[]) => void;
    placeholder?: string;
    disabled?: boolean;
}

interface State {
    selectedOptions: string[];
    changed: boolean;
    selectAll: boolean;
}

class CheckboxDropdownRenderer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedOptions: props.value ?? [],
            changed: false,
            selectAll: props.value ? props.options.length === props.value.length : false
        }
    }

    handleChange = (selectedOptions: string[]) => {
        const { onChange } = this.props;
        this.setState({ selectedOptions });
        if (onChange)
            onChange(selectedOptions);
    };

    handleMouseDown = (e: any) => {
        this.setState({ changed: false });
        e.stopPropagation();
    }

    handleCheckboxClick = (e: CheckboxChangeEvent) => {
        e.stopPropagation();
        e.preventDefault;
    }

    handleSelectAll = (e: CheckboxChangeEvent) => {
        e.stopPropagation();
        const { options, onChange } = this.props;
        const selectedOptions = e.target.checked ? options.map((option) => { return option.key; }) : [];
        this.setState({ selectedOptions });
        if (onChange)
            onChange(selectedOptions);
    }

    handleClearAll = () => {
        const { onChange } = this.props;
        this.setState({ selectedOptions: [] });
        if (onChange)
            onChange([]);
    }

    render() {
        const { t, options, placeholder, disabled } = this.props;
        const { selectedOptions } = this.state;
        return (
            <Select
                disabled={disabled}
                value={selectedOptions}
                optionLabelProp="label"
                onChange={this.handleChange}
                onMouseDown={this.handleMouseDown}
                style={{ width: "100%" }}
                mode="multiple"
                className="checkboxDropdown"
                placeholder={placeholder}
                suffixIcon={<Icon component={IoChevronDown} />}
                dropdownRender={(menu) => (
                    <>
                        <div className={styles.padding}>
                            <Checkbox
                                value="all"
                                onChange={this.handleSelectAll}
                                checked={selectedOptions.length === options.length}
                            >
                                {t("selectAll")}
                            </Checkbox>
                        </div>
                        <div className={styles.divider}>
                            <Divider />
                        </div>
                        {menu}
                        <div className={styles.divider}>
                            <Divider />
                        </div>
                        <div className={styles.clearAll}>
                            <Button
                                type="link"
                                onClick={this.handleClearAll}
                            >
                                {t("clearAll")}
                            </Button>
                        </div>
                    </>
                )}
                options={options.map((option) => {
                    return {
                        value: option.key,
                        label: (
                            <Checkbox
                                value={option.key}
                                onChange={this.handleCheckboxClick}
                                checked={selectedOptions.includes(option.key)}
                            >
                                {option.value}
                            </Checkbox>
                        )
                    }
                })}
            />
        );
    };

}

export default withTranslation(["shared"])(CheckboxDropdownRenderer);