import { TFunction } from "i18next";

function getDemoLocations(t: TFunction) {
    return [
        { key: "Chicago", value: t("Chicago") },
        { key: "Toronto", value: t("Toronto") },
        { key: "NewYork", value: t("newYork") }

    ];
}

export { getDemoLocations };
