import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SearchSelectItem from "./interfaces/search-select-item";
import styles from './styles/search-select-renderer.less';
import clsx from "clsx";

interface Props extends ICellRendererParams, WithTranslation {
    textDisplay?: boolean;
}

interface State {
    value: SearchSelectItem;
}

class SearchSelectRenderer extends Component<Props, State> {  

    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value,
        }
    }

    render() {
        const { value } = this.state;
        const { t, textDisplay } = this.props;

        return (
            value === undefined || value === null
                ? <span className={clsx(!textDisplay && styles.select, !textDisplay && styles.placeholder)}>
                    {t("select")}
                </span>
                : <span className={clsx(!textDisplay && styles.select)}>{value.name}</span>
        );
    }
}

export default withTranslation(["ui"])(SearchSelectRenderer);