import { agGridLicensing } from 'configuration';
import React from 'react';
import ReactDOM from 'react-dom';
import 'translations/shared';
import { SystemMessageStyle } from 'web-service';
import '../styles/shared/global.less';
import '../utilities/polyfills';
import FooterApp from './footer-app';
import HeaderApp from './header-app';
import SystemMessageApp from './system-message-app';

agGridLicensing.addLicenseKey();

ReactDOM.render(<HeaderApp />, document.getElementById("menu-header"));
ReactDOM.render(<FooterApp />, document.getElementById("menu-footer"));

if (document.getElementById("login-system-messages") !== null)
    ReactDOM.render(<SystemMessageApp type={SystemMessageStyle.Login} />, document.getElementById("login-system-messages"));