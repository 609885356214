// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active__S6pNB path {
  fill: #B3DFFF;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/icons/filter-icon/styles/filter-icon.module.less"],"names":[],"mappings":"AAEA;EAEQ,aAAA;AAFR","sourcesContent":["@import \"../../../../../apps/styles/style-variables.less\";\n\n.active {\n    path {\n        fill: @primaryColorLight;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `active__S6pNB`
};
export default ___CSS_LOADER_EXPORT___;
