import React from "react";
import { App as AntApp, Grid } from "antd";
import { ScreenMap } from "antd/es/_util/responsiveObserver";
import { useAppProps } from "antd/es/app/context";

interface WithApp {
    app: useAppProps;
    breakpoints: ScreenMap;
}

function withApp(Component: any) {
    const displayName = `withApp(${Component.displayName || Component.name})`;

    const C = (props: any) => {
        const { wrappedComponentRef, ...remainingProps } = props;
        const useBreakpoint: ScreenMap = Grid.useBreakpoint();
        const app: useAppProps = AntApp.useApp();

        return (
            <Component
                {...remainingProps}
                ref={wrappedComponentRef}
                app={app}
                breakpoints={useBreakpoint}
            />
        );
    };

    C.displayName = displayName;
    C.WrappedComponent = Component;

    return C;
}

export { withApp };
export type { WithApp };