import { Avatar, Badge, Button, Col, Input, InputRef, Popover, Row, Select, Space } from "antd";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MdNotifications, MdSearch } from "react-icons/md";
import { UserModel } from "user";
import { EventStatus, serviceClient } from "web-service";
import CloneEventDrawer from "./drawers/clone-event-drawer";
import FeedbackDrawer from "./drawers/feedback-drawer";
import { NotificationPane, SearchPane, UserPane } from "./dropdowns";
import styles from "./styles/client.less";
import clsx from "clsx";

const { Option } = Select;

interface Props extends WithTranslation {
    user: UserModel;
    refreshUser: () => Promise<void>;
    refreshSystemMessages: () => void;
    hasSystemMessages: boolean;
}

interface State {
    search: string;
    searchByStatus: EventStatus | undefined;
    searchEvent: string;
    searchPopover: boolean;
    searchFocused: boolean;
    eventPopover: boolean;
    notificationPopover: boolean;
    userVisible: boolean;
    feedbackVisible: boolean;
    cloneEventVisible: boolean;
}

class ClientHeader extends Component<Props, State> {
    private eventSearch: React.RefObject<InputRef>

    constructor(props: Props) {
        super(props);

        this.state = {
            search: "",
            searchByStatus: EventStatus.Live,
            searchEvent: "",
            searchPopover: false,
            searchFocused: false,
            eventPopover: false,
            notificationPopover: false,
            userVisible: false,
            feedbackVisible: false,
            cloneEventVisible: false
        }

        this.eventSearch = React.createRef<InputRef>();
    }

    searchChanged = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ search: e.currentTarget.value });
    }

    eventFilterChanged = (status: EventStatus) => {
        this.setState({ searchByStatus: status as EventStatus });
    }

    eventFilterClick = (event: any) => {
        event.stopPropagation();
    }

    getSnapshotBeforeUpdate = (prevProps: Props, prevState: any) => {
        if (prevProps.user.event?.status != this.props.user.event?.status) {
            return this.props.user.event?.status;
        }
        return null;
    }

    componentDidUpdate(prevProps: Props, prevState: any, snapshot: any) {
        if (snapshot !== null) {
            this.setState({ searchByStatus: snapshot });
        }
    }

    onVisibleChange = (visible: boolean) => {
        this.setState({
            searchPopover: visible
        });
    }

    searchEventChanged = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ searchEvent: e.currentTarget.value });
    }

    handleSearchFocus = () => {
        this.setState({ searchFocused: true })
    }

    handleSearchBlur = () => {
        this.setState({ searchFocused: false })
    }

    handleSearchClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if (this.state.searchFocused && this.state.searchPopover)
            e.stopPropagation();
    }

    notificationPopoverChanged = (visible: boolean) => {
        this.setState({ notificationPopover: visible });

        if (!visible)
            this.clearNotifications();
    }

    clearNotifications = () => {
        serviceClient.post("Clear", "Notification", {
            eventID: this.props.user.event?.id
        }).promise.then(() => this.props.refreshUser());
    }

    render() {
        const { t, user, refreshUser, refreshSystemMessages, hasSystemMessages } = this.props;

        let defaultStatus = this.state.searchByStatus;
        if (user.event?.status === EventStatus.Preview || user.event?.status === EventStatus.Demo) {
            defaultStatus = EventStatus.Preview;
        }
        else if (user.event !== undefined && user.event !== null) {
            defaultStatus = user.event?.status;
        }

        return (user.branding !== undefined) ? (
            <header className={styles.headerClient} style={{ backgroundColor: user.styles.LOGOBACKGROUNDHEX, color: user.styles.LOGOTEXTHEX }}>
                <Row align="middle" justify="space-between" className={styles.rowMenu}>
                    <Col>
                        <a href="/Client">
                            <img className={styles.logo} src={user.branding.logoUrl} alt="" />
                        </a>
                    </Col>
                    <Col className={clsx(styles.colSearch, this.state.searchPopover && styles.colSearchOpen)}>
                        {user.isLoggedIn ?
                            (
                                <Popover
                                    getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement}
                                    placement="bottomLeft"
                                    overlayClassName={clsx(styles.popoverNav, styles.popoverNavBottomLeft, styles.popoverSearch)}
                                    onOpenChange={this.onVisibleChange}
                                    content={
                                        <SearchPane
                                            user={user}
                                            filter={this.state.search}
                                            eventStatus={this.state.searchByStatus}
                                        />
                                    }
                                    trigger="click"
                                >
                                    <div className={styles.navSearchContainer}>
                                        <Input
                                            onClick={this.handleSearchClick}
                                            onFocus={this.handleSearchFocus}
                                            onBlur={this.handleSearchBlur}
                                            prefix={<MdSearch className={styles.inputIcon} />}
                                            placeholder={t("searchPlaceholder")}
                                            onChange={this.searchChanged}
                                            className={clsx(this.state.searchPopover && styles.open)}
                                            addonAfter={
                                                <Select
                                                    defaultValue={defaultStatus}
                                                    className={clsx(styles.searchSelect, "select-after")}
                                                    popupClassName={styles.searchSelectDropdown}
                                                    popupMatchSelectWidth={140}
                                                    onChange={this.eventFilterChanged}
                                                    onClick={this.eventFilterClick}
                                                >
                                                    <Option value="">{t("allEvents")}</Option>
                                                    <Option value={EventStatus.Live}> {t("liveEvents")}</Option>
                                                    <Option value={EventStatus.Preview}>{t("previewAndDemoEvents")}</Option>
                                                    <Option value={EventStatus.Archived}>{t("archivedEvents")}</Option>
                                                </Select>
                                            }
                                        />
                                    </div>
                                </Popover>
                            ) : ("")}
                    </Col>
                    <Col className={styles.colMenuItems}>
                        {user.isLoggedIn ?
                            (
                                <div className={styles.containerMenuItems}>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" href="/Client/Booking/Search" size="large">{t("allReservations")}</Button>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" href="/Client" size="large">{t("clientHome")}</Button>
                                    <Popover getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement} placement="bottomRight" overlayClassName={clsx(styles.popoverNav, styles.popoverEvents)} onOpenChange={(visible) => { this.setState({ eventPopover: visible }); if (visible) { setTimeout(() => this.eventSearch.current?.focus(), 1); } }} content={
                                        <div>
                                            <div className={styles.popoverHeader}>
                                                <Input
                                                    prefix={<MdSearch className={styles.inputIcon} />}
                                                    placeholder={t("searchEvents")}
                                                    onChange={this.searchEventChanged}
                                                    onClick={(e) => e.preventDefault()}
                                                    ref={this.eventSearch}
                                                />
                                            </div>
                                            <SearchPane type="Events" user={user} filter={this.state.searchEvent} />
                                            <div className={styles.popoverFooter}>
                                                <Space>
                                                    <Button onClick={() => this.setState({ cloneEventVisible: true })}>{t("cloneEvent")}</Button>
                                                    <Button type="primary" href={"/Client/Wizard"}>{t("createNewEvent")}</Button>
                                                </Space>
                                            </div>
                                        </div>
                                    } trigger="click">
                                        <Button style={{ color: user.styles.LOGOTEXTHEX }} className={clsx(styles.navItem, styles.navItemPop, this.state.eventPopover && styles.open)} type="link" size="large">{t("events")}</Button>
                                    </Popover>

                                    <Popover getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement} placement="bottomLeft" overlayClassName={styles.popoverNav + " " + styles.popoverNotifications} onOpenChange={this.notificationPopoverChanged} content={<NotificationPane user={user} refreshSystemMessages={refreshSystemMessages} hasSystemMessages={hasSystemMessages} />} trigger={"click"}>
                                        <Button
                                            className={clsx(styles.navItem, styles.navItemIcon, styles.navItemPop, this.state.notificationPopover && styles.open)}
                                            type="link" size="large">
                                            <Badge
                                                count={user.newNotifications > 0 ? user.newNotifications : ""}
                                                className={styles.badge}
                                                style={{ boxShadow: "0 0 0 2px " + user.styles.LOGOBACKGROUNDHEX }}
                                                offset={[7, -7]}
                                                overflowCount={9}
                                            >
                                                <MdNotifications style={{ color: user.styles.LOGOTEXTHEX }} />
                                            </Badge>
                                        </Button>
                                    </Popover>
                                    <Popover
                                        getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement}
                                        placement="bottomRight"
                                        content={<UserPane user={user}
                                            showFeedback={() => this.setState({ feedbackVisible: true })} />}
                                        trigger={"click"}
                                        overlayClassName={clsx(styles.popoverNav, styles.popoverUser)}
                                        onOpenChange={(visible) => { this.setState({ userVisible: visible }) }}>
                                        <Button style={{ color: user.styles.LOGOTEXTHEX }} className={clsx(styles.navItem, styles.navItemIcon, styles.navItemPop, this.state.userVisible && styles.open)} type="link" size="large">
                                            {
                                                user.avatarUrl === null ?
                                                    <Avatar style={{ backgroundColor: user.styles.LOGOTEXTHEX, color: user.styles.LOGOBACKGROUNDHEX }} className={clsx(styles.avatar, styles.avatarText)}>{user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}</Avatar>
                                                    :
                                                    <Avatar src={user.avatarUrl} className={styles.avatar}></Avatar>
                                            }
                                        </Button>
                                    </Popover>
                                    <FeedbackDrawer
                                        open={this.state.feedbackVisible}
                                        onClose={() => { this.setState({ feedbackVisible: false }); }}
                                        user={user} />
                                    <CloneEventDrawer open={this.state.cloneEventVisible}
                                        onClose={() => this.setState({ cloneEventVisible: false })}
                                        user={user}
                                    />
                                </div>
                            ) : (
                                <div className={styles.containerMenuItems}>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" href={user.baseUrl + "/Account/Register"}>
                                        {t("register")}
                                    </Button>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" href={user.baseUrl + "/Account/Login"}>
                                        {t("logIn")}
                                    </Button>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </header>
        ) : (<span />)
    };
}


export default withTranslation("menu")(ClientHeader);
