// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableHeader__II1sD {
  display: flex;
  background-color: white;
  z-index: 1;
}
.tableHeader__II1sD > div {
  width: 100%;
}
.tableHeader__II1sD > div > div:first-of-type {
  padding: 16px;
}
@media (max-width: 768px) {
  .table__zHUQn tr th:not([class*=ant-table-selection-column])[class*=ant-table-cell][class*=ant-table-cell-fix-left],
  .table__zHUQn tr:not([class=ant-table-measure-row]) td:not([class*=ant-table-selection-column])[class*=ant-table-cell][class*=ant-table-cell-fix-left] {
    position: static !important;
  }
}
.linearLoader__scMSv {
  width: auto !important;
  height: auto !important;
}
.linearLoader__scMSv .loadingCircle__bnEDT {
  background-image: conic-gradient(transparent 25%, #2179B7);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-mask: radial-gradient(circle 18px, transparent 98%, #FFFFFF 100%);
          mask: radial-gradient(circle 18px, transparent 98%, #FFFFFF 100%);
  animation: spin__DUdwM 1s linear infinite;
}
.linearLoader__scMSv .loadingTip__t18YX {
  font-size: 14px;
  margin-top: 8px;
  font-weight: 500;
}
@keyframes spin__DUdwM {
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/table-typed/styles/table-typed.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,uBAAA;EACA,UAAA;AADJ;AAFA;EAKQ,WAAA;AAAR;AALA;EAQY,aAAA;AAAZ;AAOI;EAGQ;;IACI,2BAAA;EANd;AACF;AAWA;EACI,sBAAA;EACA,uBAAA;AATJ;AAOA;EAIQ,0DAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yEAAA;UAAA,iEAAA;EACA,yCAAA;AARR;AADA;EAYQ,eAAA;EACA,eAAA;EACA,gBAAA;AARR;AAYA;EACI;IACI,yBAAA;EAVN;AACF","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.tableHeader {\n    display: flex;\n    background-color: white;\n    z-index: 1;\n    > div {\n        width: 100%;\n        > div:first-of-type {\n            // padding for the header (buttons, pagination) and not the banner\n            padding: 16px;\n        }\n    }\n}\n\n.table {\n    // responsive\n    @media (max-width: @screenMD) {\n        tr th:not([class*=ant-table-selection-column]),\n        tr:not([class=ant-table-measure-row]) td:not([class*=ant-table-selection-column]) {\n            &[class*=ant-table-cell][class*=ant-table-cell-fix-left] {\n                position: static !important;\n            }\n        }\n    }\n}\n\n.linearLoader {\n    width: auto !important;\n    height: auto !important;\n    .loadingCircle {\n        background-image: conic-gradient(transparent 25%, @primaryColor);\n        width: 50px;\n        height: 50px;\n        border-radius: 50%;\n        mask: radial-gradient(circle 18px, transparent 98%, #FFFFFF 100%);\n        animation: spin 1s linear infinite;\n    }\n    .loadingTip {\n        font-size: 14px;\n        margin-top: 8px;\n        font-weight: 500;\n    }\n}\n\n@keyframes spin {\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeader": `tableHeader__II1sD`,
	"table": `table__zHUQn`,
	"linearLoader": `linearLoader__scMSv`,
	"loadingCircle": `loadingCircle__bnEDT`,
	"spin": `spin__DUdwM`,
	"loadingTip": `loadingTip__t18YX`
};
export default ___CSS_LOADER_EXPORT___;
