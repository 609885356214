// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testStatusBar__JJn_W {
  height: 42px;
  display: grid;
  place-content: center;
  position: relative;
  top: 0;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #FF6F5C;
  color: #F6F5EC;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/menu/styles/test-status-bar.less"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,cAAA;AADJ","sourcesContent":["@import \"../../../apps/styles/style-variables.less\";\n\n.testStatusBar {\n    height: @layoutTriggerHeight;\n    display: grid;\n    place-content: center;\n    position: relative;\n    top: 0;\n    width: 100%;\n    font-size: 1.5rem;\n    font-weight: 600;\n    background-color: @errorColorNeon;\n    color: @resiLight;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"testStatusBar": `testStatusBar__JJn_W`
};
export default ___CSS_LOADER_EXPORT___;
