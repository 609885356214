import { Dayjs } from "translations";
import { i18next } from "translations";
import { ComparisonType } from "web-service";
import { FilterValue } from "./filter-value";

i18next.setDefaultNamespace("ui");

class FilterValueDate extends FilterValue<Dayjs> {
    setLabel() {
        if (this.value) {
            const valueStr = i18next.t("ui:filterDate", { date: this.value });

            if (this.comparisonType === ComparisonType.Range && this.value2) {
                const value2Str = i18next.t("ui:filterDate", { date: this.value })

                this.label = i18next.t("ui:toxy", { x: valueStr, y: value2Str }).toString();
            }
            else {
                this.label = valueStr;
            }
        }

        return this;
    }
}

export { FilterValueDate };
