import React, { createContext, ReactNode } from "react";
import { serviceClient } from 'web-service';
import { setTimezone } from "../translations";
import { UserModel } from "./interfaces/user-model";

type UserContext = {
    user: UserModel;
    refreshUser: () => Promise<void>;
    afterLogin: (func: Function) => void;
};

let defaultUser = {
    isLoggedIn: false,
    baseUrl: '',
    branding: {
        displayName: '',
        addresses: [],
        copyright: '',
        displayEmail: '',
        privacyUrl: '',
        termsUrl: '',
        brandUrl: '',
        logoUrl: '',
        poweredBy: false,
        beaconId: null
    },
    eventBranding: {
        displayName: '',
        addresses: [],
        copyright: '',
        displayEmail: '',
        privacyUrl: '',
        termsUrl: '',
        brandUrl: '',
        logoUrl: '',
        poweredBy: false,
        beaconId: null
    },
    styles: {},
    event: null,
    firstName: '',
    lastName: '',
    email: '',
    id: '',
    avatarUrl: '',
    clientID: '',
    clientName: '',
    clientPermissions: [],
    newNotifications: 0,
    logoUrl: '', 
    supportTimer: '',
    languageName: '',
    languageID: '',
    timeZone: 'UTC',
    dayjsTimeZone: 'UTC',
    keys: {
        telemetryKey: "",
        geoCodeKey: "",
        hotJarSettings: "",
        flywheelKey: ""
    }
} as UserModel;

export const UserContext = createContext<UserContext>(
    {
        user: defaultUser,
        refreshUser: () => new Promise((resolve, reject) => resolve()),
        afterLogin: () => { }
    });

type Props = {
    children: ReactNode
}

type State = {
    user: UserModel | null
}

class UserProvider extends React.Component<Props, State>{
    initalQuery: boolean = false;
    afterLogins: Array<Function> = []; 

    constructor(props : Props) {
        super(props);
        this.state = {
            user: null
        };
    }

    render() {
        const { children } = this.props;
        const { user } = this.state;

        const setUser = (user: UserModel) => {
            this.setState({ user }, () => {

                setTimezone(user.dayjsTimeZone);

                for (const afterLogin of this.afterLogins) {
                    afterLogin();
                }

                this.afterLogins = [];
            });
        };

        const refreshUser = () => {
            const element = document.getElementById('event-v') as HTMLInputElement;
            this.initalQuery = true;

            let result: Promise<void> = new Promise(() => {});

            if (element != null) {        //Get Current User. (the event-v field hack is to bridge the gap until we implement react routing.)
                result = serviceClient.get('Current/' + element.value, 'User').promise.then(function (data) {
                    setUser(data);
                });
            }

            return result;
        }

        const afterLogin = (func: Function) => {
            if (user == null) {
                this.afterLogins = this.afterLogins.concat(func);
            }
            else {
                func();
            }
        }

        if (!this.initalQuery) {
            refreshUser(); 
        }

        return (
            <UserContext.Provider value={{ user: (user || defaultUser), refreshUser, afterLogin }}>
                {children}
            </UserContext.Provider>
        );
    };
}


export default UserProvider;
