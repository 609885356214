// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__J5dsj {
  border-radius: 0;
  border: none;
  width: 100%;
}
.select__J5dsj > div {
  border-radius: 0 !important;
  border: none !important;
  padding: 5px !important;
}
.select__J5dsj > div .ant-select-selection-search {
  left: 5px !important;
}
.select__J5dsj input {
  font-size: 12px;
}
.select__J5dsj div span {
  font-size: 12px;
  line-height: 24px !important;
}
.select__J5dsj span[aria-hidden="true"] {
  top: 50%;
}
.selectMultiple__QQQWS > div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.selectDropdown__JRLeX div[aria-selected='false'],
.selectDropdown__JRLeX div[aria-selected='true'] {
  font-size: 12px;
  min-height: 30px;
  padding: 5px 10px;
}
.selectTagEditor__gwvmb {
  font-size: 11px !important;
  font-weight: 500;
  padding: 0 5px;
  margin-right: 5px;
}
.selectTagEditor__gwvmb span[aria-label="close"] {
  line-height: 22px !important;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/search-select/styles/search-select-editor.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,YAAA;EACA,WAAA;AADJ;AAFA;EAMQ,2BAAA;EACA,uBAAA;EACA,uBAAA;AADR;AAPA;EAagB,oBAAA;AAHhB;AAVA;EAmBQ,eAAA;AANR;AAbA;EAuBQ,eAAA;EACA,4BAAA;AAPR;AAjBA;EA4BQ,QAAA;AARR;AAYA;EACI,yBAAA;EACA,4BAAA;EACA,YAAA;AAVJ;AAaA;;EAGQ,eAAA;EACA,gBAAA;EACA,iBAAA;AAZR;AAeA;EACI,0BAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AAbJ;AASA;EAOQ,4BAAA;AAbR","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.select {\n    border-radius: 0;\n    border: none;\n    width: 100%;\n\n    > div {\n        border-radius: 0 !important;\n        border: none !important;\n        padding: @agPadding !important;\n\n        :global {\n            //positioning the search text to line up with aggrid\n            .ant-select-selection-search {\n                left: @agPadding !important;\n            }\n        }\n    }\n\n    input {\n        font-size: @agFontSize;\n    }\n\n    div span {\n        font-size: @agFontSize;\n        line-height: 24px !important;\n    }\n    //positioning the icons in the middle\n    span[aria-hidden=\"true\"] {\n        top: 50%;\n    }\n}\n\n.selectMultiple > div {\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n    height: 100%;\n}\n\n.selectDropdown {\n    div[aria-selected='false'],\n    div[aria-selected='true'] {\n        font-size: @agFontSize;\n        min-height: 30px;\n        padding: @agPadding (@agPadding*2);\n    }\n}\n.selectTagEditor {\n    font-size: 11px !important;\n    font-weight: 500;\n    padding: 0 5px;\n    margin-right: 5px;\n    //vertically centering the X in the tag:\n    span[aria-label=\"close\"] {\n        line-height: 22px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `select__J5dsj`,
	"selectMultiple": `selectMultiple__QQQWS`,
	"selectDropdown": `selectDropdown__JRLeX`,
	"selectTagEditor": `selectTagEditor__gwvmb`
};
export default ___CSS_LOADER_EXPORT___;
