import { Col, Input, Row } from "antd";
import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ComparisonType } from "web-service";
import { filterConstants, FilterInputProps, FilterValue } from "../data";
import FilterListInputLabel from "./filter-list-input-label";
import styles from "./styles/filter-list-textbox.module.less";

interface Props extends FilterInputProps<string>, WithTranslation {
    editStep?: string | undefined;
    table?: boolean;
}

interface State {
    visible: boolean;
}

class FilterListTextboxSingle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { values, showVisibilityToggle } = props;

        this.state = {
            visible: (showVisibilityToggle === undefined || showVisibilityToggle === false || (showVisibilityToggle && values !== undefined && values.length > 0))
        };
    }

    /**
     * Process input on change value
     * @param e Input value
     */
    handleChange = (e: React.ChangeEvent<HTMLInputElement>, ix: number) => {
        const { filter, values, onChange } = this.props;
        const stringValue = e.target.value ?? "";

        const newValues = cloneDeep(values ?? []);
        const value = new FilterValue<string>().assign(stringValue, stringValue, undefined, ComparisonType.Contains);

        while (newValues.length < ix)
            newValues.push(new FilterValue());

        newValues[ix] = value;

        if (onChange && filter)
            onChange(filter, newValues);
    };

    handleVisibilityChange = () => {
        const { filter, onChange } = this.props;
        const { visible } = this.state;

        const newVisible = !visible;

        if (!newVisible && filter && onChange)
            onChange(filter, []);

        this.setState({ visible: newVisible });
    }

    render() {
        const { label, values, editStep, table } = this.props;
        const { visible } = this.state;

        const trueValue = values ?? [];

        return (
            <Row align="middle">
                <FilterListInputLabel<string>
                    {...this.props}
                    open={visible || trueValue.length > 0}
                    onOpenChange={this.handleVisibilityChange}
                    options={filterConstants.textOptions}
                    table={table}
                />
                {
                    (visible || trueValue.length > 0) &&
                    <Col span={24}>
                        <Input
                            className={styles.input}
                            style={editStep ? {} : { marginRight: "1em", maxWidth: "100%" }}                            
                            type="textbox"
                            placeholder={label?.toString()}
                            onChange={(e) => this.handleChange(e, 0)}
                            value={trueValue.length > 0 ? trueValue[0].value : ""}
                            allowClear={true}
                            role="textbox"
                        />
                    </Col>
                }
            </Row>
        );
    }
}

export default withTranslation(["ui"])(FilterListTextboxSingle);