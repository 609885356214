// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse__t3FzO .ant-collapse-item .ant-collapse-header,
.collapse__t3FzO .ant-collapse-content-box {
  padding: 0!important;
}
.collapse__t3FzO.noTitle__ZdZXk .ant-collapse-item .ant-collapse-header {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/collapse-height/styles/index.less"],"names":[],"mappings":"AAAA;;EAIY,oBAAA;AADZ;AAKI;EAEQ,aAAA;AAJZ","sourcesContent":[".collapse {\n    :global {\n        .ant-collapse-item .ant-collapse-header,\n        .ant-collapse-content-box {\n            padding: 0!important;\n        }\n    }\n\n    &.noTitle :global {\n        .ant-collapse-item .ant-collapse-header {\n            display: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": `collapse__t3FzO`,
	"noTitle": `noTitle__ZdZXk`
};
export default ___CSS_LOADER_EXPORT___;
