import { theme, ThemeConfig } from "antd";


// constant color variables
export const styleVars = {
    colorPrimary: "#357AB9",

    // Brand colours
    colorSuccess: "#21B0B7",
    colorSuccessNeon: "#43BDED",
    colorSuccessDark: "#177D82",
    colorSuccessLite: "#DBFDFF",

    colorWarning: "#F5BC41",
    colorWarningNeon: "#FF905E",
    colorWarningDark: "#8D6207",
    colorWarningLite: "#FFEBBF",

    colorError: "#FF6F5C",
    colorErrorNeon: "#FF4D4F",
    colorErrorDark: "#BD1600",
    colorErrorLite: "#FFD2CC",

    linkColor: "#357AB9",

    // Grays
    grayOne: "#717171",
    grayTwo: "#A7A7A7",
    grayThree: "#DDDDDD",
    grayFour: "#EDEDED",
    grayFive: "#F5F5F5",
    graySix: "#F7F7FC",
    graySeven: "#FAFAFA",
    grayEight: "#FDFDFD",

    // Fonts
    headingTextColor: "rgba(0, 0, 0, 0.85)",
    textColor: "rgba(0, 0, 0, 0.65)",
    baseFontFamily: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
};


export const globalTheme: ThemeConfig = {
    // apply ant design theme
    token: {
        // fonts
        fontFamily: styleVars.baseFontFamily,
        lineHeight: 1.5,
        fontSize: 14,
        fontSizeLG: 15,
        fontSizeXL: 16,

        colorText: styleVars.textColor,
        colorTextHeading: styleVars.textColor,
        colorTextDescription: styleVars.grayOne,
        colorTextSecondary: styleVars.grayOne,

        // colors
        colorPrimary: styleVars.colorPrimary,
        colorSuccess: styleVars.colorSuccess,
        colorWarning: styleVars.colorWarning,
        colorError: styleVars.colorError,
        colorLink: styleVars.linkColor,
        colorInfo: styleVars.colorPrimary,
        colorInfoText: styleVars.colorPrimary,

        borderRadius: 4,

        linkHoverDecoration: "none",
        linkFocusDecoration: "none"
    },
    components: {
        Button: {
            borderRadius: 4,

            marginXS: 4,        // space between icon and text
            fontWeight: 500,

            // LG Button
            contentFontSizeLG: 14,

            // SM button
            contentFontSizeSM: 12,
            paddingBlockSM: 2.6,
            paddingInlineSM: 16,

            // Primary Button - Filled
            colorPrimary: styleVars.colorPrimary,
            colorPrimaryBorder: styleVars.colorPrimary,
            colorTextLightSolid: "white",
            primaryShadow: "none",
            colorLink: styleVars.linkColor,

            // Default Button - Outlined
            defaultColor: styleVars.colorPrimary,
            defaultBorderColor: styleVars.colorPrimary,
            defaultBg: "rgba(255, 255, 255, 0.3)",
            defaultShadow: "none",
        },
        Dropdown: {
            paddingXXS: 0,      // options padding
        },
        Form: {
            controlHeight: 0
        },
        Modal: {
            titleColor: styleVars.headingTextColor,
            colorText: styleVars.textColor,
            borderRadiusLG: 6,  // modal border radius
            marginXS: 8,        // spacing between title and content | cancel and ok button,
        },
        Notification: {
            marginXS: 0,        // message margin bottom,
            fontSizeLG: 17,     // message
            fontSize: 16,       // description
        },
        Select: {
            paddingXXS: 0,      // options padding
        },
        Table: {
            headerBg: "transparent",
            rowHoverBg: styleVars.graySeven,
            rowSelectedBg: styleVars.graySix,
            rowSelectedHoverBg: "#e2f0f4",
        },
        Tooltip: {
            colorBgSpotlight: "rgba(0, 0, 0, 0.80)",
            fontSize: 12,
        },
        Typography: {
            titleMarginTop: "0",
            titleMarginBottom: "0.5rem",
            colorError: styleVars.colorError,
            colorSuccess: styleVars.colorSuccess,
            colorWarning: styleVars.colorWarning,
            colorInfo: styleVars.colorPrimary,
        },
        Tag: {
            lineWidth: 0
        },
    },
    algorithm: theme.defaultAlgorithm,
};
