import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { dayjs } from "./day-js";

let userTimeZone = "UTC";

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {},
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false,
            format: function (value, format, _culture, options) {
                if (dayjs.isDayjs(value)) {
                    if (options && options["time"])
                        return value.tz(userTimeZone).format(format);
                    else
                        return value.tz("UTC").format(format);
                }
                else if (value instanceof Date)
                    return dayjs.utc(value).tz("UTC").format(format);
                else if (format === "currency" && options && options?.currency)
                    return formatCurrency(value, options.currency);
                else if (format === "currencyRounded" && options && options?.currency)
                    return formatCurrencyRounded(value, options.currency);
                else if (format === "percent")
                    return formatPercent(value);
                else if (Number.isInteger(value) || Number.isFinite(value))
                    return (new Intl.NumberFormat(i18next.language)).format(value);
                else
                    return value;
            }
        }
    });

function formatCurrency(value: any, currency: any): string {
    const acronym = typeof currency == "string" ? currency : currency?.acronym ?? "";
    const formatOptions = {
        maximumFractionDigits: 2,
        style: "currency",
        currency: acronym,
        currencyDisplay: 'narrowSymbol'
    };
    try {
        return (new Intl.NumberFormat(i18next.language, formatOptions)).format(value);
    } catch (ex) {
        return value;
    }
}

function formatCurrencyRounded(value: any, currency: any): string {
    const acronym = typeof currency == "string" ? currency : currency?.acronym ?? "";
    const formatOptions = {
        maximumFractionDigits: 0,
        style: "currency",
        currency: acronym,
        currencyDisplay: 'narrowSymbol'
    };

    try {
        return (new Intl.NumberFormat(i18next.language, formatOptions)).format(value);
    }
    catch (ex) {
        return value;
    }

}

function formatPercent(value: any): string {
    const formatOptions = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: "percent",
    };
    try {
        return (new Intl.NumberFormat(i18next.language, formatOptions)).format(value);
    } catch (ex) {
        return value;
    }
}

function setTimezone(newTimeZone: string) {
    userTimeZone = newTimeZone;
    dayjs.tz.setDefault(userTimeZone);
}

export default i18next;
export { setTimezone };