// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__adTxr {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.inputItem__mw0aT {
  border-radius: 0;
  border: none;
  padding: 5px;
  font-size: 12px;
  flex-grow: 1;
  height: 100%;
}
div.inputItemNumber__yg0_3 {
  border-radius: 0;
  border: none;
  font-size: 12px;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}
div.inputItemNumber__yg0_3 input {
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/input/styles/input-editor.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AADJ;AAGA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;AADJ;AAGA;EACI,gBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AADJ;AALA;EASQ,YAAA;AADR","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.form {\n    flex: 1 1 auto;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n.inputItem {\n    border-radius: 0;\n    border: none;\n    padding: @agPadding;\n    font-size: @agFontSize;\n    flex-grow: 1;\n    height: 100%;\n}\ndiv.inputItemNumber {\n    border-radius: 0;\n    border: none;\n    font-size: @agFontSize;\n    flex-grow: 1;\n    height: 100%;\n    width: 100%;\n\n    input {\n        padding: @agPadding;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `form__adTxr`,
	"inputItem": `inputItem__mw0aT`,
	"inputItemNumber": `inputItemNumber__yg0_3`
};
export default ___CSS_LOADER_EXPORT___;
