import { Button, Col, Drawer, Row } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MdLaunch } from 'react-icons/md';
import { UserModel } from 'user';
import status from '../data/status';
import { LinkPane } from "../dropdowns";
import styles from "./styles/event.less";
import clsx from "clsx";

interface Props extends WithTranslation {
    user: UserModel;
    className?: string;
    isTestSite: boolean;
}

interface State {
    linksDrawer: boolean
}

class EventHeader extends Component<Props, State> {
    constructor(props : Props) {
        super(props);

        this.state = {
            linksDrawer: false
        }
    }

    render() {
        const { t, user, className, isTestSite } = this.props;

        return (user.event != undefined) ? (
            <header
                className={clsx(styles.headerEvent, className, {
                    [styles.removeBorderRadius]: isTestSite
                })}
                style={{ backgroundColor: user.styles.HEADERMENUHEX, color: user.styles.HEADERMENUTEXTHEX }}
            >
                <Row align="middle" justify="space-between" className={styles.rowMenu}>
                    <Col className={clsx(styles.colStatus, status.getStatusClass(user.event.status, styles))}>
                        <a href={user.baseUrl + "/Event/Status"}>{status.getStatus(user.event.status, t)}</a>
                    </Col>
                    <Col className={styles.colEventName}>
                        <Drawer
                            rootClassName={styles.drawerLinksMobile}
                            styles={{ body: { padding: 0 } }}
                            closable
                            placement="left"
                            width="90%"
                            onClose={() => this.setState({ linksDrawer: false })}
                            open={this.state.linksDrawer}
                        >
                            <LinkPane user={user} />
                        </Drawer>
                        <Button
                            type="link"
                            className={clsx(styles.navItem, styles.navItemEvent, styles.navItemPop)}
                            style={{ color: user.styles.HEADERMENUTEXTHEX }}
                            onClick={() => this.setState({linksDrawer: true})}
                        >
                            {user.event.name} <MdLaunch />
                        </Button>
                    </Col>
                </Row>
            </header>
        ) : (<span />);
    }
}

export default withTranslation('menu')(EventHeader);