import Icon from "@ant-design/icons";
import { Col, List, Row, Tooltip } from "antd";
import React, { Component } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { withTranslation, WithTranslation } from "react-i18next";
import { IoLockClosedOutline } from "react-icons/io5";
import { ExtendedValue } from "./interface/extended-value";
import styles from "./styles/column-editor-item.module.less";
import { HighlightText } from "../typography";
import { DragHandleIcon } from "../icons";
import clsx from "clsx";

interface Props extends WithTranslation {
    dragHandleProps?: DraggableProvidedDragHandleProps;
    sortable?: boolean;
    value: ExtendedValue;
    icon: React.ReactNode;
    searchValue: string;
    dragging?: boolean;
    hasError?: boolean;
}

/**
 * Class for rendering the column editor item
 */
class ColumnEditorItem extends Component<Props> {

    render() {
        const { t, sortable, value, dragHandleProps, icon, searchValue, hasError } = this.props;
        return (
            <>
                {
                    value.label &&
                    <List.Item className={clsx(styles.item, hasError && styles.hasError)}>
                        <Row wrap={false} className={clsx(styles.inner, {[styles.locked]: value.locked})} justify="space-between" align="middle">
                            <Col flex="auto">
                                <span {...dragHandleProps}>
                                    {
                                        value.locked ?
                                            <Icon className={styles.icon} component={IoLockClosedOutline} />
                                            : sortable &&
                                            <>
                                                {
                                                    !!searchValue
                                                        ? <Tooltip title={t("dragDisabledHint")}>
                                                            <DragHandleIcon aria-label={t("dragHandle")} className={clsx(styles.icon, styles.disabled)} />
                                                        </Tooltip>
                                                        : <DragHandleIcon aria-label={t("dragHandle")} className={styles.icon} />
                                                }
                                            </>
                                    }
                                </span>
                                <HighlightText
                                    ellipsis
                                    strong
                                    className={styles.text}
                                    value={value.label.toString()}
                                    highlight={searchValue}
                                />
                            </Col>
                            <Col>
                                <span className={styles.close}>{!value.locked && <>{icon}</>}</span>
                            </Col>
                        </Row>
                    </List.Item>
                }
            </>
        );
    }
}

export default withTranslation(["ui"])(ColumnEditorItem);
