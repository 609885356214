import { App as AntApp, ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import i18next from 'i18next';
import { dayjs } from "translations";
import React, { Component } from 'react';
import { HeaderMenu } from '../../modules/menu';
import { UserProvider } from "../../modules/user";
import { globalTheme } from "../styles/shared/global-theme";

type Props = {};

class HeaderContainer extends Component<Props> {
    constructor(props: Props) {
        super(props);

        dayjs.locale('en-us'); //Match dayjs and antd configurations.
        i18next.changeLanguage('en-us'); //Match i18Next and antd configurations.
    }

    render() {
        return (
            <UserProvider>
                <ConfigProvider locale={enUS} theme={globalTheme}>
                    <AntApp>
                        <HeaderMenu />
                    </AntApp>
                </ConfigProvider>
            </UserProvider>
        );
    }
}

export default HeaderContainer;