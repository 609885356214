import { LabeledValue } from "antd/es/tree-select";
import { i18next } from "translations";
import { ComparisonType } from "web-service";

class FilterConstants {

    readonly filterSeparator: string = ",";

    readonly separator: string = " ";

    readonly bytes: number = 1024;

    readonly dateOptions: LabeledValue[] = [
        {
            label: i18next.t("ui:equal"),
            value: ComparisonType.Equal
        },
        {
            label: i18next.t("ui:notEqual"),
            value: ComparisonType.NotEqual
        },
        {
            label: i18next.t("ui:dateRange"),
            value: ComparisonType.Range
        }
    ];

    readonly numericListOptions: LabeledValue[] = [
        {
            label: i18next.t("ui:equal"),
            value: ComparisonType.Equal
        },
        {
            label: i18next.t("ui:notEqual"),
            value: ComparisonType.NotEqual
        },
        {
            label: i18next.t("ui:between"),
            value: ComparisonType.Range
        }
    ];

    readonly textOptions: LabeledValue[] = [
        {
            label: i18next.t("ui:equal"),
            value: ComparisonType.Equal
        },
        {
            label: i18next.t("ui:notEqual"),
            value: ComparisonType.NotEqual
        },
        {
            label: i18next.t("ui:contains"),
            value: ComparisonType.Contains
        },
        {
            label: i18next.t("ui:notContains"),
            value: ComparisonType.NotContains
        },
        {
            label: i18next.t("ui:startsWith"),
            value: ComparisonType.StartsWith
        },
        {
            label: i18next.t("ui:endsWith"),
            value: ComparisonType.EndsWith
        }
    ];

    readonly selectOptions: LabeledValue[] = [
        {
            label: i18next.t("ui:equal"),
            value: ComparisonType.Equal
        },
        {
            label: i18next.t("ui:notEqual"),
            value: ComparisonType.NotEqual
        }
    ];
}

const filterConstants = new FilterConstants();

export default filterConstants;