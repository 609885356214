enum Feature {
    CreditCardPayment = 2,
    EventCancellationFee = 3,
    EventCustomizedRatesPerBlock = 4,
    EventMultiLanguage = 5,
    EventMinimumNight = 6,
    HotelMinimumNight = 7,
    SubBlockMinimumNight = 8,
    BillToMasterPayment = 9,
    CheckPayment = 10,
    WireTransferPayment = 11,
    ContractedBlocks = 12,
    NonContractedBlocks = 13,
    CashPayment = 14,
    SubBlockCancellationFee = 15
}

export default Feature;