import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MdLock } from "react-icons/md";
import { TaxType } from "../../../web-service";
import { PendingEventTax } from "../interfaces/event-taxes";
import styles from "./styles/event-tax-name-renderer.less";

interface Props extends ICellRendererParams, WithTranslation {
    hideLockIcon?:boolean
}

class EventTaxNameRenderer extends Component<Props> {
    render() {
        const { hideLockIcon } = this.props;
        const value: PendingEventTax = this.props.data;

        return (
            <div className={!(value.eventTax.hotelSpecific && value.eventTax.taxType !== TaxType.SubBlockFee) ? styles.icon : ""}>
                {(!value.eventTax.hotelSpecific && value.eventTax.taxType !== TaxType.SubBlockFee && !hideLockIcon ? <MdLock /> : "" )}
                <span>{value.eventTax.translation.name}</span>
            </div>
        );
    }

}

export default withTranslation(["event"])(EventTaxNameRenderer);