import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Menu, Button } from 'antd';
import { permissionLookup } from 'security';

import styles from './styles/event-menu.less';
import { UserModel } from 'user';

const { Item } = Menu;

interface Props extends WithTranslation {
    user: UserModel;
    switchPane: (pane: string) => void;
}

class EventMenu extends Component<Props> {
    constructor(props : Props) {
        super(props);
    }


    render() {
        const { t, user, switchPane } = this.props;

        return (
            <Menu
                mode="inline"
                className={styles.mobileMenu + " " + styles.mobileMenuEvent}
                style={{ backgroundColor: user.styles.HEADERMENUHEX, color: user.styles.HEADERMENUTEXTHEX }}
            >             
                <Item key="1" className={styles.mobileMenuItem}>
                    <Button style={{ color: user.styles.HEADERMENUTEXTHEX }} className={styles.navItem} type="link" size="large" href={user.baseUrl + '/Dashboard'}>{t('dashboard')}</Button>
                </Item>
                {user.event != null && user.event.eventPermissions.includes(permissionLookup.REPORT_PLANNER) ?
                    <Item className={styles.mobileMenuItem} key="2" onClick={() => switchPane('reports')}>
                        <Button style={{ color: user.styles.HEADERMENUTEXTHEX }} className={styles.navItem} type="link" size="large">{t('reports')}</Button>
                    </Item> : ""} 
                {user.event != null && user.event.eventPermissions.includes(permissionLookup.RESERVATION_VIEW) ?
                    <Item className={styles.mobileMenuItem} key="3">
                        <Button style={{ color: user.styles.HEADERMENUTEXTHEX }} className={styles.navItem} type="link" size="large" href={user.baseUrl + '/Booking/Search'}>{t('eventReservations')}</Button>
                    </Item> : ""}               
                {user.event != null && user.event.eventPermissions.includes(permissionLookup.EVENT_MANAGEMENT) ?
                    <Item className={styles.mobileMenuItem} key="4">
                        <Button style={{ color: user.styles.HEADERMENUTEXTHEX }} className={styles.navItem} type="link" size="large" onClick={() => switchPane('manage')}>{t('manage')}</Button>
                    </Item> : ""} 
            </Menu>
        );
    }
}


export default withTranslation('menu')(EventMenu);
