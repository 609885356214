import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { UserContext } from "../user";

import styles from "./styles/footer.less";

interface Props extends WithTranslation {

}

class FooterMenu extends Component<Props> {
    constructor(props : Props) {
        super(props);
    }
    
    render() {

        const t = this.props.t;

        return (
            <UserContext.Consumer>
                {({ user }) => user.branding !== undefined ? (
                    <div style={{ backgroundColor: user.styles.FOOTERMENUHEX }}>                       
                        <footer>
                            <Row align="middle" justify="space-between" className={styles.footer}>
                                <Col>
                                    <a style={{ color: user.styles.FOOTERMENUTEXTHEX }} href={user.branding.brandUrl} target="_blank" aria-label={user.branding.displayName}>
                                        {user.branding.copyright}
                                    </a>
                                </Col>
                                <Col className={styles.links}>
                                    <a style={{ color: user.styles.FOOTERMENUTEXTHEX }} target="_blank" href={user.baseUrl + "/Privacy/AddRequest"}>{t('privacyRequest')}</a>
                                    <a style={{ color: user.styles.FOOTERMENUTEXTHEX }} target="_blank" href={user.branding.termsUrl}>{t('termsOfService')}</a>
                                    <a style={{ color: user.styles.FOOTERMENUTEXTHEX }} target="_blank" href={user.branding.privacyUrl}>{t('privacyPolicy')}</a>
                                </Col>
                            </Row>
                        </footer>                     
                    </div>
                ) : (<div></div>)}
            </UserContext.Consumer>
        );
    }
}

export default withTranslation('menu')(FooterMenu);