// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuListContainer__DRvyI .menuList__fhIt8 {
  padding: 32px 40px;
}
.menuListItem__XxUXf {
  font-size: 14px;
  line-height: 1.4;
  padding: 5px 0 !important;
  border: 0 !important;
  font-weight: 500;
}
.menuListItem__XxUXf a {
  color: rgba(0, 0, 0, 0.65);
}
.menuListItem__XxUXf a:hover,
.menuListItem__XxUXf a:focus,
.menuListItem__XxUXf a:active {
  color: #2179B7;
}
@media only screen and (max-width: 991px) {
  .menuListItem__XxUXf {
    padding: 8px 0;
    font-size: 15px;
  }
}
.menuListTitle__jX0Rb {
  font-family: 'Poppins', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  margin-bottom: 12px;
  padding-bottom: 4px;
  font-size: 15px;
  border-bottom: 1px solid #cccccc;
}
@media only screen and (max-width: 991px) {
  .menuListTitle__jX0Rb {
    margin-bottom: 16px;
    font-size: 16px;
  }
}
.popoverHeader__UQC4S .menuListTitle__jX0Rb {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/menu/dropdowns/styles/manage-pane.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAIA;EACI,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,oBAAA;EACA,gBAAA;AAFJ;AAHA;EAQQ,0BAAA;AAFR;AAIQ;;;EACI,cAAA;AAAZ;AAII;EAAA;IACI,cAAA;IACA,eAAA;EADN;AACF;AAGA;EACI,+MAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AADJ;AAGI;EAAA;IACI,mBAAA;IACA,eAAA;EAAN;AACF;AAGA;EAEI,gBAAA;EACA,gBAAA;EACA,iBAAA;AAFJ","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.menuListContainer .menuList {\n    padding: 32px 40px;\n}\n\n.menuListItem {\n    font-size: @fontSizeBase;\n    line-height: 1.4;\n    padding: 5px 0 !important;\n    border: 0 !important;\n    font-weight: 500;\n\n    a {\n        color: @textColor;\n\n        &:hover, &:focus, &:active {\n            color: @primaryColor;\n        }\n    }\n\n    @media @mobileOnly {\n        padding: 8px 0;\n        font-size: @fontSizeBase + 1;\n    }\n}\n.menuListTitle {\n    font-family: @fontFamilySecondary;\n    font-weight: 600;\n    margin-bottom: 12px;\n    padding-bottom: 4px;\n    font-size: @fontSizeBase + 1;\n    border-bottom: 1px solid #cccccc;\n\n    @media @mobileOnly {\n        margin-bottom: 16px;\n        font-size: @fontSizeBase + 2;\n    }\n}\n\n.popoverHeader .menuListTitle {\n    // no border or spacing when its inside a header\n    border-bottom: 0;\n    margin-bottom: 0;\n    padding-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuListContainer": `menuListContainer__DRvyI`,
	"menuList": `menuList__fhIt8`,
	"menuListItem": `menuListItem__XxUXf`,
	"menuListTitle": `menuListTitle__jX0Rb`,
	"popoverHeader": `popoverHeader__UQC4S`
};
export default ___CSS_LOADER_EXPORT___;
