// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderComponent__GkQH9 {
  max-height: 100vh !important;
}
.loaderComponent__GkQH9 .ant-spin-text {
  text-shadow: none !important;
  transform: translate(-50%, -50%);
  left: 50%;
  width: auto !important;
  margin-top: 38px;
  font-size: 14px;
  font-weight: 500;
}
.loaderComponent__GkQH9[class*="ant-spin-lg"] [class="ant-spin-text"] {
  margin-left: 6px;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/loading/styles/loader.module.less"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;AAFA;EAIY,4BAAA;EACA,gCAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AACZ;AAEI;EACI,gBAAA;AAAR","sourcesContent":[".loaderComponent {\n    max-height: 100vh !important;\n    :global {\n        .ant-spin-text {\n            text-shadow: none !important;\n            transform: translate(-50%, -50%);\n            left: 50%;\n            width: auto !important;\n            margin-top: 38px;\n            font-size: 14px;\n            font-weight: 500;\n        }\n    }\n    &[class*=\"ant-spin-lg\"] [class=\"ant-spin-text\"] {\n        margin-left: 6px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderComponent": `loaderComponent__GkQH9`
};
export default ___CSS_LOADER_EXPORT___;
