// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row__JEe1N {
  margin-top: 1em;
}
.date__rpDqw {
  width: 100%;
  border-radius: 4px;
}
.date__rpDqw > div:nth-of-type(1) {
  margin-left: 8px;
  order: 2 !important;
}
.date__rpDqw > div:nth-of-type(2) {
  order: 3 !important;
}
.date__rpDqw > div:nth-of-type(3) {
  order: 5;
}
.date__rpDqw > div:nth-of-type(4) {
  order: 4;
}
.date__rpDqw > span {
  order: 1;
}
.date__rpDqw .ant-picker-suffix {
  margin-bottom: 1px;
  margin-left: 0;
}
.single__metFG [class=ant-picker-input] {
  flex-direction: row-reverse;
}
.single__metFG [class=ant-picker-input] > input {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/filters/styles/filter-input-value.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAEA;EACI,WAAA;EACA,kBAAA;AAAJ;AAEI;EACI,gBAAA;EACA,mBAAA;AAAR;AAGI;EACI,mBAAA;AADR;AAII;EACI,QAAA;AAFR;AAKI;EACI,QAAA;AAHR;AAMI;EACI,QAAA;AAJR;AAlBA;EA0BY,kBAAA;EACA,cAAA;AALZ;AAUA;EAGQ,2BAAA;AAVR;AAYQ;EACI,gBAAA;AAVZ","sourcesContent":[".row {\n    margin-top: 1em;\n}\n\n.date {\n    width: 100%;\n    border-radius: 4px;\n\n    &>div:nth-of-type(1) {\n        margin-left: 8px;\n        order: 2 !important;\n    }\n\n    &>div:nth-of-type(2) {\n        order: 3 !important;\n    }\n\n    &>div:nth-of-type(3) {\n        order: 5;\n    }\n\n    &>div:nth-of-type(4) {\n        order: 4;\n    }\n\n    &>span {\n        order: 1;\n    }\n    :global {\n        .ant-picker-suffix {\n            margin-bottom: 1px;\n            margin-left: 0;\n        }\n    }\n}\n\n.single {\n    // Date Picker == flips icons to left and input to right\n    [class=ant-picker-input] {\n        flex-direction: row-reverse;\n\n        &>input {\n            margin-left: 8px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `row__JEe1N`,
	"date": `date__rpDqw`,
	"single": `single__metFG`
};
export default ___CSS_LOADER_EXPORT___;
