// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link__kOpzP {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link__kOpzP:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/link/styles/link-renderer.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;AAAI;EACI,0BAAA;AAER","sourcesContent":[".link {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    &:hover {\n        text-decoration: underline;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `link__kOpzP`
};
export default ___CSS_LOADER_EXPORT___;
