import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Currency, TaxType } from "web-service";
import { PendingEventTax } from "../interfaces/event-taxes";

interface Props extends ICellRendererParams, WithTranslation {
    currency?: Currency
}

class EventTaxAmountRenderer extends Component<Props> {
    render() {
        const { t, currency } = this.props;
        const value: PendingEventTax = this.props.data;
        
        if (!value || !value.eventTax.amount)
            return "";

        return (
            <span>
                {(value.eventTax.taxType == TaxType.Tax ? 
                    t("percentageFormat", { n: value.eventTax.amount / 100 })
                    : t("currencyFormat", { n: value.eventTax.amount, currency: currency  })
                )}
            </span>
        );
    }

}

export default withTranslation(["event"])(EventTaxAmountRenderer);