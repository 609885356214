class ValidationConstants {

    readonly phoneRegEx: RegExp = /^[-0-9]*$/;

    readonly emailRegEx: RegExp = /^[a-zA-Z0-9]*(?:[\._-][a-zA-Z0-9](?:[a-zA-Z0-9]*[a-zA-Z0-9])?)*@[a-zA-Z0-9]*(?:[\.-][a-zA-Z0-9](?:[a-zA-Z0-9]*[a-zA-Z0-9])?)*\.([A-Za-z]{2,})$/;

    readonly timeRegEx: RegExp = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

    readonly numeric: RegExp = /^[0-9]*$/;

    readonly decimal: RegExp = /^\d*\.?\d*$/;

    readonly urlComponentRegex: RegExp = /^[0-9a-zA-Z\-_!]*$/;

    readonly urlRegex: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    readonly dateFormatMoment: string = "MM/DD/YYYY";

    readonly dateTimeFormatMoment: string = "MM/DD/YYYY hh:mm:ss a";
 
    readonly dateTimeNoSecondsFormatMoment: string = "MM/DD/YYYY hh:mm a";

}

const validationConstants = new ValidationConstants();

export default validationConstants;