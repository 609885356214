interface SavedFilterGetResponse extends SavedFilterRequest {
    /**
     * View Id
     * */
    id: string;
};

interface SavedFilterRequest {
    /**
     * View Name
     * */
    name: string;

    /**
     * Filter Options
     * */
    filterItems: SavedFilterItemModel<string>[];

    /**
     * Default
     * */
    default: boolean;
}

interface SavedFilterItemModel<T> {
    value: T;
    field: string;
    comparisonType: ComparisonType;
}

enum FilterTable {
    Booking = 0
}

enum ComparisonType {
    Equal = 0,
    NotEqual = 1,
    Contains = 2,
    NotContains = 3,
    Range = 4,
    StartsWith = 5,
    EndsWith = 6
}

export type { SavedFilterGetResponse, SavedFilterItemModel, SavedFilterRequest };
export { FilterTable, ComparisonType };