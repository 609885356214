enum FeedbackType {
    General = 10,
    Bug = 20,
    Suggestion = 30,
    Feature = 40,
    Content = 50,
    Other = 60,
    Sales = 70
}

export default FeedbackType;