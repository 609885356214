// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%2764 64 896 896%27 focusable=%27false%27 class=%27%27 data-icon=%27down%27 width=%271em%27 height=%271em%27 fill=%27rgba%280, 0, 0, 0.25%29%27 aria-hidden=%27true%27 %3E%3Cpath d=%27M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z%27%3E%3C/path%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__Om0UO {
  padding-right: 18px;
}
.select__Om0UO:after {
  content: '';
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  position: absolute;
  top: 50%;
  right: 15px;
  width: 16px;
  height: 15px;
  margin-top: -8px;
  z-index: 1;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
  transform: scale(0.6);
}
.placeholder__EMYlL {
  color: rgba(0, 0, 0, 0.33) !important;
}
.selectTag__aDK2t {
  margin-left: 7px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  padding: 0 5px;
  position: relative;
  top: -1px;
  color: rgba(0, 0, 0, 0.55);
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/search-select/styles/search-select-renderer.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,WAAA;EACA,yDAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;EACA,wBAAA;EACA,kCAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,qBAAA;AACR;AAEA;EACI,qCAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,0BAAA;AADJ","sourcesContent":[".select {\n    padding-right: 18px;\n    //showing the chevron in render mode\n    &:after {\n        content: '';\n        background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' class='' data-icon='down' width='1em' height='1em' fill='rgba(0, 0, 0, 0.25)' aria-hidden='true' %3E%3Cpath d='M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z'%3E%3C/path%3E%3C/svg%3E\");\n        display: inline-block;\n        font-style: normal;\n        line-height: 0;\n        text-transform: none;\n        vertical-align: -0.125em;\n        text-rendering: optimizeLegibility;\n        position: absolute;\n        top: 50%;\n        right: 15px;\n        width: 16px;\n        height: 15px;\n        margin-top: -8px;\n        z-index: 1;\n        font-size: 12px;\n        line-height: 1;\n        text-align: center;\n        pointer-events: none;\n        transform: scale(0.6);\n    }\n}   \n.placeholder {\n    color: rgba(0, 0, 0, 0.33) !important;\n}\n\n.selectTag {\n    margin-left: 7px;\n    font-size: 10px;\n    line-height: 16px;\n    font-weight: 500;\n    padding: 0 5px;\n    position: relative;\n    top: -1px;\n    color: rgba(0, 0, 0, 0.55);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `select__Om0UO`,
	"placeholder": `placeholder__EMYlL`,
	"selectTag": `selectTag__aDK2t`
};
export default ___CSS_LOADER_EXPORT___;
