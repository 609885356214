// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row__Idp4W {
  margin-top: 1em;
}
.input__ytg_F input {
  padding-left: 0.25em !important;
}
.tag__KSP04 {
  background: #B3DFFF;
  border: unset;
  border-radius: 50px;
}
.tag__KSP04 .tagClose__CNVIU svg {
  transform: scale(1.5);
}
.textColor__nGHE9 {
  color: rgba(0, 0, 0, 0.85);
}
.filterListName__GWnc6 [class*=ant-typography] {
  color: #717171;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/filters/components/styles/filter-list-textbox.module.less"],"names":[],"mappings":"AAEA;EACI,eAAA;AADJ;AAIA;EAEQ,+BAAA;AAHR;AAOA;EACI,mBAAA;EACA,aAAA;EACA,mBAAA;AALJ;AAEA;EAMQ,qBAAA;AALR;AASA;EACI,0BAAA;AAPJ;AAUA;EAEQ,cAAA;EACA,gBAAA;AATR","sourcesContent":["@import \"../../../../../apps/styles/style-variables.less\";\n\n.row {\n    margin-top: 1em;\n}\n\n.input {\n    input {\n        padding-left: .25em !important;\n    }\n}\n\n.tag {\n    background: @primaryColorLight;\n    border: unset;\n    border-radius: @cornerRoundTag;\n\n    .tagClose svg {\n        transform: scale(1.5);\n    }\n}\n\n.textColor {\n    color: @textDarker;\n}\n\n.filterListName {\n    [class*=ant-typography] {\n        color: @grayOne;\n        font-weight: 500;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `row__Idp4W`,
	"input": `input__ytg_F`,
	"tag": `tag__KSP04`,
	"tagClose": `tagClose__CNVIU`,
	"textColor": `textColor__nGHE9`,
	"filterListName": `filterListName__GWnc6`
};
export default ___CSS_LOADER_EXPORT___;
