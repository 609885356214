// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomNavigation__H3hsw {
  margin-top: 24px;
}
.bottomNavigation__H3hsw .bottomNavContainer__RFE0Y {
  background-color: transparent;
  z-index: 5;
}
.bottomNavigation__H3hsw .ant-affix [class*="bottomNavContainer"] {
  background-color: white;
  padding: 24px 0;
  box-shadow: 0 0 0 100vmax white;
  -webkit-clip-path: inset(0 -100vmax);
          clip-path: inset(0 -100vmax);
}
.bottomNavigation__H3hsw .ant-affix [class*="bottomNavContainer"]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #DDDDDD;
  box-shadow: 0 0 0 100vmax #DDDDDD;
  -webkit-clip-path: inset(0 -100vmax);
          clip-path: inset(0 -100vmax);
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/button/styles/affixed-navigation-buttons.module.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;AAAA;EAGQ,6BAAA;EACA,UAAA;AAAR;AAJA;EAQY,uBAAA;EACA,eAAA;EACA,+BAAA;EACA,oCAAA;UAAA,4BAAA;AADZ;AAEY;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,yBAAA;EACA,iCAAA;EACA,oCAAA;UAAA,4BAAA;AAAhB","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.bottomNavigation {\n    margin-top: 24px;\n    .bottomNavContainer {\n        background-color: transparent;\n        z-index: 5;\n    }\n    :global {\n        .ant-affix [class*=\"bottomNavContainer\"] {\n            background-color: white;\n            padding: 24px 0;\n            box-shadow: 0 0 0 100vmax white;\n            clip-path: inset(0 -100vmax);\n            &:before {\n                content: \"\";\n                position: absolute;\n                top: 0;\n                left: 0;\n                right: 0;\n                height: 1px;\n                background-color: @grayThree;\n                box-shadow: 0 0 0 100vmax @grayThree;\n                clip-path: inset(0 -100vmax);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomNavigation": `bottomNavigation__H3hsw`,
	"bottomNavContainer": `bottomNavContainer__RFE0Y`
};
export default ___CSS_LOADER_EXPORT___;
