// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__EwNPX {
  height: 54px;
  flex-wrap: nowrap;
  font-weight: 500;
  font-size: 11px;
  z-index: 1;
  padding: 0 12px;
  position: relative;
  box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.3);
}
.footer__EwNPX > div {
  z-index: 1;
}
.footer__EwNPX:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to right, rgba(212, 212, 212, 0) 0%, #d4d4d4 100%);
  opacity: 0.2;
  top: 0;
  left: 0;
}
.footer__EwNPX a {
  white-space: nowrap;
  opacity: 1;
}
.footer__EwNPX a:hover {
  opacity: 0.6;
}
.links__M28bG a:not(:first-of-type) {
  margin-left: 12px;
}
@media (max-width: 768px) {
  .footer__EwNPX {
    height: 108px;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .footer__EwNPX > * {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer__EwNPX > *:first-of-type {
    padding-bottom: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/menu/styles/footer.less"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EAEA,oDAAA;AAFJ;AAPA;EAYQ,UAAA;AAFR;AAKI;EAEI,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,8EAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;AAJR;AApBA;EA4BQ,mBAAA;EACA,UAAA;AALR;AAOQ;EACI,YAAA;AALZ;AAUA;EACI,iBAAA;AARJ;AAWA;EACI;IACI,aAAA;IACA,uBAAA;IACA,qBAAA;IACA,eAAA;EATN;EAKE;IAOQ,WAAA;IACA,aAAA;IACA,eAAA;IACA,uBAAA;EATV;EAWU;IACI,oBAAA;EATd;AACF","sourcesContent":["@import \"../../../apps/styles/style-variables.less\";\n\n.footer {\n    height: @layoutHeaderHeight;\n    flex-wrap: nowrap;\n    font-weight: 500;\n    font-size: @fontSizeHeader - 2;\n    z-index: 1;\n    padding: 0 @headerLrPadding;\n    position: relative;\n\n    box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.3);\n\n    > div {\n        z-index: 1;\n    }\n\n    &:after {\n        // slight gradient overlay from right to left (same gradient as client)\n        content: \"\";\n        width: 100%;\n        height: 100%;\n        position: absolute;\n        background: @overlayClient;\n        opacity: 0.2;\n        top: 0;\n        left: 0;\n    }\n\n    a {\n        white-space: nowrap;\n        opacity: 1;\n\n        &:hover {\n            opacity: @menuHoverOpacity;\n        }\n    }\n}\n\n.links a:not(:first-of-type) {\n    margin-left: @headerLrPadding;\n}\n\n@media (max-width: @screenMD){\n    .footer {\n        height: @layoutHeaderHeight * 2;\n        justify-content: center;\n        align-content: center;\n        flex-wrap: wrap;\n\n        > * {\n            width: 100%;\n            display: flex;\n            flex-wrap: wrap;\n            justify-content: center;\n\n            &:first-of-type {\n                padding-bottom: @headerLrPadding;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer__EwNPX`,
	"links": `links__M28bG`
};
export default ___CSS_LOADER_EXPORT___;
