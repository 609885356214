import AppStorage from "./storage";

/**
 * Class to interact with local storage
 * Note: Currently not used
 */
class SessionAppStorage extends AppStorage {

    /**
     * Retrieves object stored in session storage (json format), load into memory
     */
    public load(): void {
        let storageJson = sessionStorage.getItem(this.storageKey);
        if (storageJson == null) {
            this.jsonObject = {};

            return;
        }

        this.jsonObject = JSON.parse(storageJson) as any;
    }

    /**
     * Save information to session storage as json
     */
    public save(): void {
        sessionStorage.setItem(this.storageKey, JSON.stringify(this.jsonObject));
    }
}

const sessionAppStorage = new SessionAppStorage();

export default sessionAppStorage;