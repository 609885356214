import { Button, Col, Space } from "antd";
import React, { Component, ReactElement } from "react";
import { i18next } from "translations";
import { FilterInputProps, FilterValue } from "./data";
import FilterConfirmation from "./filter-confirmation";

i18next.setDefaultNamespace("ui");

interface FilterResults {
    key: string;
    values: FilterValue<any>[];
}

interface Props<T> {
    values: FilterResults[];
    propMap: Map<string, FilterInputProps<T>>;
    modalConfirmOpen: boolean;

    onReset: (type: "all" | "undo") => void;
    onApplyChange: () => void;

    header?: ReactElement;
}

/**
 * Component for viewing the Filter Drawer Results.
 * */
class FilterDrawerResults<T> extends Component<Props<T>> {

    render() {
        const { values, propMap, modalConfirmOpen, onReset, onApplyChange, header } = this.props;

        return (
            <Col>
                <Space direction="horizontal" size={8}>
                    {header}
                    {
                        values.length > 0 && i18next.t("ui:filterBy")
                    }
                    <b>
                        {
                            // Comma separated list of filters applied
                            values.map(v => propMap.get(v.key)?.label).join(", ")
                        }
                    </b>
                    {
                        values.length > 0 &&
                        <Button style={{ height: "30px", padding: 0 }} type="link" onClick={() => onReset("all")}>
                            {i18next.t<string>("ui:clearAll").toString()}
                        </Button>
                    }
                </Space>
                <FilterConfirmation
                    open={modalConfirmOpen}
                    onOk={onApplyChange}
                    onCancel={() => onReset("undo")}
                />
            </Col>
        );
    }
}

export default FilterDrawerResults;