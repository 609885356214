import { UserModel } from "user";
import { unescape } from "lodash";

interface TranslationResponse {
    data: {
        translations: Array<{
            translatedText: string;
        }>
    }
}

class TranslateClient {
    readonly baseUrl: string = `https://translation.googleapis.com/language/translate/v2`;
    
    public translate(user: UserModel, text: string, soureLng: string, targetLng: string) {
        let url = this.baseUrl + `?key=${user.keys.geoCodeKey}&q=${encodeURIComponent(text)}&source=${soureLng.slice(0, 2)}&target=${targetLng.slice(0, 2) }`;

        return fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        })
            .then(response =>
                response.json().then((result: TranslationResponse) =>
                    ({ result: result.data.translations.map(t => unescape(t.translatedText)), response }))
            ).then(({ result, response }) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return result;
            });
    }
};

const translateClient = new TranslateClient();

export default translateClient;