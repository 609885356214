import { ICellRendererParams } from "ag-grid-community";
import { ButtonProps, DropDownProps, MenuProps } from "antd";
import { DropdownButtonType } from "antd/es/dropdown";

interface ButtonGridProps extends GridProps {
    buttonProps: ButtonProps;
}

interface DropdownUIProps extends DropDownProps {
    type?: DropdownButtonType;
    icon?: React.ReactNode;
}

interface DropdownButtonGridProps extends GridProps {
    dropdownProps: DropdownUIProps;
    menu: MenuProps;
}

interface GridProps extends ICellRendererParams {
    onClick: (params: ICellRendererParams) => void;
    onRender?: (params: ICellRendererParams) => boolean;
    className?: string;
    getDisabled?: () => boolean;
}


function isButtonGrid(object: any): object is ButtonGridProps {
    return 'buttonProps' in object;
}

export { isButtonGrid };
export type { ButtonGridProps, DropdownButtonGridProps, GridProps };
