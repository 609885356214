import React, { Component } from 'react';
import { UserModel } from 'user';
import { SearchSelect, SearchSelectProps } from '../search-select';

interface Props extends SearchSelectProps {
    user: UserModel;
}

class EventSelect extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { user, ...other } = this.props;

        return (
            <SearchSelect {...other}
                defaultValue={user.event == null ? undefined : { value: user.event.id, name: user.event.name, disabled: false }}
                controller="Event"
                action="Select"
            />
        );
    }
}

export default (EventSelect);