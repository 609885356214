import React from "react";

export default function ContractIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#DFF8F9"/>
            <g clipPath="url(#clip0_1887_14683)">
                <rect width="16" height="16" transform="translate(4 4)" fill="white" fillOpacity="0.01"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 8.66667C7.5 8.02233 8.02233 7.5 8.66667 7.5H15.3333C15.9777 7.5 16.5 8.02233 16.5 8.66667V10.3333C16.5 10.9777 15.9777 11.5 15.3333 11.5H8.66667C8.02233 11.5 7.5 10.9777 7.5 10.3333V8.66667ZM8.66667 8.5C8.57462 8.5 8.5 8.57462 8.5 8.66667V10.3333C8.5 10.4254 8.57462 10.5 8.66667 10.5H15.3333C15.4254 10.5 15.5 10.4254 15.5 10.3333V8.66667C15.5 8.57462 15.4254 8.5 15.3333 8.5H8.66667Z" fill="#1A8C92"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 13.6667C7.5 13.0223 8.02233 12.5 8.66667 12.5H15.3333C15.9777 12.5 16.5 13.0223 16.5 13.6667V15.3333C16.5 15.9777 15.9777 16.5 15.3333 16.5H8.66667C8.02233 16.5 7.5 15.9777 7.5 15.3333V13.6667ZM8.66667 13.5C8.57462 13.5 8.5 13.5746 8.5 13.6667V15.3333C8.5 15.4254 8.57462 15.5 8.66667 15.5H15.3333C15.4254 15.5 15.5 15.4254 15.5 15.3333V13.6667C15.5 13.5746 15.4254 13.5 15.3333 13.5H8.66667Z" fill="#1A8C92"/>            </g>
            <defs>
                <clipPath id="clip0_1887_14683">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
