import { App as AntApp, ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import i18next from 'i18next';
import { dayjs } from "translations";
import React, { Component } from 'react';
import { UserContext } from 'user';
import SystemMessageList from '../../modules/menu/system-messages/system-message-list';
import { UserProvider } from "../../modules/user";
import { SystemMessageStyle } from 'web-service';

type Props = {
    type: SystemMessageStyle;
};

class SystemMessageContainer extends Component<Props> {
    constructor(props: Props) {
        super(props);

        dayjs.locale('en-us'); //Match antd configurations.
        i18next.changeLanguage('en-us'); //Match i18Next and antd configurations.
    }

    render() {
        return (
            <UserProvider>
                <ConfigProvider locale={enUS}>
                    <AntApp>
                        <UserContext.Consumer>
                            {({ user }) =>
                                <SystemMessageList user={user} type={this.props.type} />
                            }
                        </UserContext.Consumer>
                    </AntApp>
                </ConfigProvider>
            </UserProvider>
        );
    }
}

export default SystemMessageContainer;