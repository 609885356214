import * as React from "react";

function SVGCalendarIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
			fill="none"
		>
			<path
				d="M13 2.5H3A1.5 1.5 0 001.5 4v9A1.5 1.5 0 003 14.5h10a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0013 2.5z"
				stroke="#303030"
				strokeLinejoin="round"
			/>
			<path
				d="M4 1.5v1M12 1.5v1M14.5 5h-13M9.5 8.125l1.357-1H11v5.25M5.996 9.582c.285 0 .806-.134 1.147-.483a1.184 1.184 0 00.348-.852C7.491 7.43 6.785 7 5.995 7c-.67 0-1.048.363-1.183.592"
				stroke="#303030"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.656 11.693c.153.258.616.807 1.372.807.893 0 1.628-.498 1.628-1.37 0-.394-.114-.75-.362-1.002-.386-.394-.976-.546-1.298-.546"
				stroke="#303030"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default SVGCalendarIcon;