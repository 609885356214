// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon__Ha2BY {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon__Ha2BY svg {
  margin-right: 5px;
  color: #2179B7;
}
.iconCheckCirclColor__FShIf {
  color: #46BD16 !important;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/tax/renderers/styles/event-tax-name-renderer.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AADJ;AAFA;EAMQ,iBAAA;EACA,cAAA;AADR;AAKA;EACI,yBAAA;AAHJ","sourcesContent":["@import \"../../../../../apps/styles/style-variables.less\";\n\n.icon {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n\n    svg {\n        margin-right: 5px;\n        color: @primaryColorNeon;\n    }\n}\n\n.iconCheckCirclColor {\n    color: #46BD16 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon__Ha2BY`,
	"iconCheckCirclColor": `iconCheckCirclColor__FShIf`
};
export default ___CSS_LOADER_EXPORT___;
