// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carouselCommon__KtGtj {
  width: 100%;
  height: 100%;
}
.carouselCommon__KtGtj .slick-list,
.carouselCommon__KtGtj .slick-list .slick-track,
.carouselCommon__KtGtj .slick-list .slick-track .slick-slide > div,
.carouselCommon__KtGtj .slick-list .slick-track .slick-slide > div > div,
.carouselCommon__KtGtj .slick-list .ant-image,
.carouselCommon__KtGtj .slick-list .ant-image img {
  width: 100%;
  height: 100%;
}
.carouselCommon__KtGtj .slick-list .ant-image img {
  -o-object-fit: cover;
     object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/carousel/styles/component-carousel.less"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;AAAJ;AAFA;;;;;;EAWY,WAAA;EACA,YAAA;AADZ;AAXA;EAgBY,oBAAA;KAAA,iBAAA;AAFZ","sourcesContent":["\n.carouselCommon {\n    width: 100%;\n    height: 100%;\n\n    :global {\n        .slick-list,\n        .slick-list .slick-track,\n        .slick-list .slick-track .slick-slide > div,\n        .slick-list .slick-track .slick-slide > div > div,\n        .slick-list .ant-image,\n        .slick-list .ant-image img {\n            width: 100%;\n            height: 100%;\n        }\n\n        .slick-list .ant-image img {\n            object-fit: cover;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carouselCommon": `carouselCommon__KtGtj`
};
export default ___CSS_LOADER_EXPORT___;
