import { PaymentBillToMaster, PaymentCash, PaymentCheck, PaymentCreditCard, PaymentHoldPaymentClosing, PaymentWireTransfer } from "payment";
import { SearchSelectItem } from "ui";
import { EventStatus } from "../event";
import { PaymentMethod } from "../payment";
import { BookingListConfirmationModel } from "./booking-list-confirmation-model";


interface BookingListItemModel {
    id: string | undefined;
    event: BookingEventModel | undefined;
    group: BookingGroupModel;
    booking: BookingModel;
    payment: PaymentModel | undefined;
    guests: Array<GuestModel>;
    otherRequests: string | undefined;
    dirty: boolean | undefined;
    rowNodeID: string | undefined;
    tags?: string[] | undefined;
}

interface BookingEventModel {
    id: string;
    name: string;
    status: EventStatus;
}

interface BookingGroupModel {
    id: string | undefined;
    bookingID: string;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    company: string | undefined;
    externalReference?: string | undefined;
    externalSource?: string | undefined;
}

interface BookingModel {
    checkIn: string | undefined;
    checkOut: string | undefined;
    subBlockGroup: SearchSelectItem | undefined;
    hotel: SearchSelectItem | undefined;
    room: SearchSelectItem | undefined;
    guests: number;
    promotionCode: string | undefined;
    roomingListNotes: string | undefined;
    accessCode: string | undefined;
    bookingID: string | undefined;
    bookingStatus: BookingStatus | undefined;
    paymentStatus: PaymentStatus | undefined;
    depositPaymentMethod: PaymentMethod | undefined;
    bookingType: BookingType;
    requests: Array<SearchSelectItem> | undefined;
    enabledRequestPayment: boolean | undefined
    
    roomNightTotal?: number;    
    taxTotal?: number;    
    depositDueNow?: number;    
    dueAtHotel?: number;    
    feeDueNow?: number;    
    totalDueNow?: number;
    language: SearchSelectItem | undefined;
    bookingConfirmations: BookingListConfirmationModel[];
}

interface PaymentModel {
    bookingPaymentMethod?: SearchSelectItem | null;
    cardRequired: boolean;
    creditCard?: PaymentCreditCard | null;
    wireTransfer?: PaymentWireTransfer | null;
    check?: PaymentCheck | null;
    billToMaster?: PaymentBillToMaster | null;
    holdPaymentClosing?: PaymentHoldPaymentClosing | null;
    cash?: PaymentCash | null;
}

interface GuestModel {
    id: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    alternativeEmail: string | undefined,
    loyalty: string | undefined;
    primary: boolean;
}


interface AddressModel {
    address1: string;
    address2: string;
    city: string;
    state: string;
    stateOther: string;
    zip: string;
    country: string;
}

interface BookingRoomRequest {   
    rooms: number;
    days: Array<BookingDay>;
    bookingType: BookingType;
}

interface BookingDay {  
    day: string;
    bookingType: BookingType;
    existingReservation?: boolean;
}

enum BookingType {
    Reservation = 1,
    WaitList = 2,
    Partial = 3,
    None = 4
}

enum BookingStatus {
    PaymentError = -4,
    Initial = -3,
    Error = -2,
    Request = -1,
    Cancelled = 0,
    Pending = 1,
    Completed = 2,
    Abandoned = 3
}

enum PaymentStatus {
    requiresPayment = 0,
    partial = 1,
    pending = 2,
    paid = 3
}

interface SolutionItem {
    start: string;
    end: string;
    canReserve: boolean;
    canWaitList: boolean;
    selected: BookingType;
}

interface BookingCompleteModel {
    payment?: PaymentModel;
    bookingIDs: Array<string>;
}

interface BookingSelectGroupItemModel extends SearchSelectItem {
    eventID: string;
}

enum CompletionTypeOption {
    CreditCard = 0,
    Check = 10,
    CreditCardBypass = 20,
    BillToMaster = 30,
    WireTransfer = 40,
    Cash = 50,
    HoldPaymentClosing = 60
}

enum ActionType {
    GroupBookingDetails = "groupBookingDetails",
    GuestBookingDetails = "guestBookingDetails",
    GuestPaymentRequest = "guestPaymentRequest",
    GuestInformationReminder = "guestInformationReminder"
}


export { BookingListItemModel, BookingGroupModel, BookingModel, PaymentModel, GuestModel, AddressModel, BookingRoomRequest, BookingDay, BookingType, BookingStatus, PaymentStatus, SolutionItem, BookingCompleteModel, BookingSelectGroupItemModel, CompletionTypeOption, ActionType };
