// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* eslint-disable */

.DokaOverlay {
    position: relative;
    overflow: hidden;
}

.DokaOverlay > img {
    display: block;
    width: 100%;
    height: auto;
}

.DokaOverlay > .DokaContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/third-party/doka/index.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;AAChB","sourcesContent":["/* eslint-disable */\r\n\r\n.DokaOverlay {\r\n    position: relative;\r\n    overflow: hidden;\r\n}\r\n\r\n.DokaOverlay > img {\r\n    display: block;\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.DokaOverlay > .DokaContainer {\r\n    position: absolute;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
