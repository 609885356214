// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__abpFR {
  opacity: 0;
  pointer-events: none;
}
.select__abpFR:not(.tableView__APFy0) [class=ant-select-selection-item] {
  text-align: right;
}
.select__abpFR.tableView__APFy0 [class=ant-select-selector] {
  padding-left: 0;
}
.select__abpFR[class*=ant-select-open] [class=ant-select-selection-item],
.select__abpFR [class=ant-select-selection-item],
.select__abpFR [class=ant-select-arrow] {
  color: #2179B7;
}
.select__abpFR.visible__ZSS9F {
  opacity: 1;
  pointer-events: initial;
}
.textColor__JPIHu {
  color: rgba(0, 0, 0, 0.85);
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/filters/components/styles/filter-list-input-label.module.less"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,oBAAA;AADJ;AAGI;EACI,iBAAA;AADR;AAII;EACI,eAAA;AAFR;AAKI;;;EAGI,cAAA;AAHR;AAMI;EACI,UAAA;EACA,uBAAA;AAJR;AAQA;EACI,0BAAA;AANJ","sourcesContent":["@import \"../../../../../apps/styles/style-variables.less\";\n\n.select {\n    opacity: 0;\n    pointer-events: none;\n\n    &:not(.tableView) [class=ant-select-selection-item] {\n        text-align: right;\n    }\n\n    &.tableView [class=ant-select-selector] {\n        padding-left: 0;\n    }\n\n    &[class*=ant-select-open] [class=ant-select-selection-item],\n    [class=ant-select-selection-item],\n    [class=ant-select-arrow] {\n        color: @primaryColor;\n    }\n\n    &.visible {\n        opacity: 1;\n        pointer-events: initial;\n    }\n}\n\n.textColor {\n    color: @textDarker;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `select__abpFR`,
	"tableView": `tableView__APFy0`,
	"visible": `visible__ZSS9F`,
	"textColor": `textColor__JPIHu`
};
export default ___CSS_LOADER_EXPORT___;
