// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item__uQkyf {
  padding: 6px 0;
  margin-right: 0.25rem;
  background-color: white;
  transition: background-color 0.2s ease-in-out;
}
.item__uQkyf.hasError__KQPiB {
  background-color: #FFD2CC;
}
.item__uQkyf .inner__Zsg2x {
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  width: 100%;
  gap: 8px;
}
.item__uQkyf .inner__Zsg2x .disabled__xRJ4b {
  opacity: 0.5;
  cursor: not-allowed;
}
.item__uQkyf .inner__Zsg2x .icon__VUZKN,
.item__uQkyf .inner__Zsg2x .text__oXP5P {
  margin-left: 0.5em;
}
.item__uQkyf .inner__Zsg2x .icon__VUZKN {
  vertical-align: bottom;
  min-width: 24px;
  font-size: 1.5rem;
  transition: opacity 0.2s ease-in-out;
}
.item__uQkyf .inner__Zsg2x .text__oXP5P {
  max-width: calc(100% - 2em);
  vertical-align: text-top;
}
.item__uQkyf .inner__Zsg2x .close__AzyCz {
  cursor: pointer;
  margin-right: 8px;
  padding: 0;
  vertical-align: text-bottom;
  font-size: 1.5rem;
}
.item__uQkyf .inner__Zsg2x .close__AzyCz svg {
  color: #2179B7;
  font-size: 1.5rem;
}
.item__uQkyf .inner__Zsg2x.locked__PqL5r .icon__VUZKN {
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/column-editor/styles/column-editor-item.module.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,qBAAA;EACA,uBAAA;EACA,6CAAA;AADJ;AAGI;EACI,yBAAA;AADR;AANA;EAWQ,kBAAA;EACA,4BAAA;EACA,WAAA;EACA,QAAA;AAFR;AAZA;EAiBY,YAAA;EACA,mBAAA;AAFZ;AAhBA;;EAsBY,kBAAA;AAFZ;AApBA;EA0BY,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;AAHZ;AA1BA;EAgCY,2BAAA;EACA,wBAAA;AAHZ;AA9BA;EAqCY,eAAA;EACA,iBAAA;EACA,UAAA;EACA,2BAAA;EACA,iBAAA;AAJZ;AArCA;EA4CgB,cAAA;EACA,iBAAA;AAJhB;AAQQ;EACI,mBAAA;AANZ","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.item {\n    padding: 6px 0;\n    margin-right: .25rem;\n    background-color: white;\n    transition: background-color .2s ease-in-out;\n\n    &.hasError {\n        background-color: @errorColorLight;\n    }\n\n    .inner {\n        border-radius: 8px;\n        transition: .2s ease-in-out;\n        width: 100%;\n        gap: 8px;\n\n        .disabled {\n            opacity: 0.5;\n            cursor: not-allowed;\n        }\n\n        .icon, .text {\n            margin-left: .5em;\n        }\n\n        .icon {\n            vertical-align: bottom;\n            min-width: 24px;\n            font-size: 1.5rem;\n            transition: opacity .2s ease-in-out;\n        }\n        .text {\n            max-width: ~\"calc(100% - 2em)\";\n            vertical-align: text-top;\n        }\n\n        .close {\n            cursor: pointer;\n            margin-right: 8px;\n            padding: 0;\n            vertical-align: text-bottom;\n            font-size: 1.5rem;\n\n            svg {\n                color: @primaryColorNeon;\n                font-size: 1.5rem;\n            }\n        }\n\n        &.locked .icon {\n            cursor: not-allowed;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `item__uQkyf`,
	"hasError": `hasError__KQPiB`,
	"inner": `inner__Zsg2x`,
	"disabled": `disabled__xRJ4b`,
	"icon": `icon__VUZKN`,
	"text": `text__oXP5P`,
	"close": `close__AzyCz`,
	"locked": `locked__PqL5r`
};
export default ___CSS_LOADER_EXPORT___;
