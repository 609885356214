function createScriptTag(type: string, script?: string, src?: string, id?: string) {
    const scriptTag = document.createElement("script");

    scriptTag.type = type;
    if (script)
        scriptTag.innerHTML = script;
    if (src)
        scriptTag.src = src;
    if (id)
        scriptTag.id = id;

    return scriptTag;
}

export { createScriptTag };