// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Warning__QexFF {
  box-shadow: 0px 1px 10px 2px rgba(248, 148, 6, 0.25);
}
.Warning__QexFF i {
  color: #f89406;
}
.Error__IlrpM {
  box-shadow: 0px 1px 10px 2px rgba(188, 18, 52, 0.25);
}
.Error__IlrpM i {
  color: #bc1234;
}
.Success__jzkwA {
  box-shadow: 0px 1px 10px 2px rgba(91, 186, 71, 0.25);
}
.Success__jzkwA i {
  color: #5bba47;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/menu/styles/notification-listener.less"],"names":[],"mappings":"AAOA;EAII,oDAAA;AATJ;AAKA;EAEQ,cAAA;AAJR;AAQA;EAII,oDAAA;AATJ;AAKA;EAEQ,cAAA;AAJR;AAQA;EAII,oDAAA;AATJ;AAKA;EAEQ,cAAA;AAJR","sourcesContent":["@import \"../../../apps/styles/style-variables.less\";\n\n//I copied these colors from the plugin we were previously using for toasts (Toastr). The lass names match the descriptions from the DB, don't change them.\n\n.Info {\n    // default styling\n}\n.Warning {\n    i {\n        color: rgb(248, 148, 6);\n    }\n    box-shadow: @navShadow rgba(248, 148, 6, 0.25);\n}\n.Error {\n    i {\n        color: rgb(188, 18, 52);\n    }\n    box-shadow: @navShadow rgba(188, 18, 52, 0.25);\n}\n.Success {\n    i {\n        color: rgb(91, 186, 71);\n    }\n    box-shadow: @navShadow rgba(91, 186, 71, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Warning": `Warning__QexFF`,
	"Error": `Error__IlrpM`,
	"Success": `Success__jzkwA`
};
export default ___CSS_LOADER_EXPORT___;
