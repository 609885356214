import { Col, Row, Select, Tag } from "antd";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ComparisonType } from "web-service";
import { filterConstants, FilterInputProps, FilterValue } from "../data";
import FilterListInputLabel from "./filter-list-input-label";
import styles from "./styles/filter-list-textbox.module.less";
import Icon from "@ant-design/icons";
import { IoClose } from "react-icons/io5";

interface Props extends FilterInputProps<string>, WithTranslation {
    editStep?: string | undefined;
    table?: boolean;
}

interface State {
    visible: boolean;
}

class FilterListTextbox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { values, showVisibilityToggle } = props;

        this.state = {
            visible: (showVisibilityToggle === undefined || showVisibilityToggle === false || (showVisibilityToggle && values !== undefined && values.length > 0))
        };
    }

    handleVisibilityChange = () => {
        const { filter, onChange, values } = this.props;
        const { visible } = this.state;

        const newVisible = !(visible || (values && values.length > 0));

        if (!newVisible && filter && onChange)
            onChange(filter, []);

        this.setState({ visible: newVisible });
    }

    /**
     * Process input on change value
     * @param e Input value
     */
    handleChange = (e: React.Key | React.Key[]) => {
        const { filter, onChange, defaultComparisonType, values } = this.props;

        const comparison = values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Contains;

        const selected = Array.isArray(e) ? e : [e];

        if (onChange && filter)
            onChange(filter, selected.map(s => new FilterValue<string>(s.toString(), comparison)));
    };

    render() {
        const { label, values, limit, table } = this.props;
        const { visible } = this.state;

        const trueValue = values ?? [];

        return (
            <Row align="middle">
                <FilterListInputLabel<string>
                    {...this.props}
                    open={visible || trueValue.length > 0}
                    onOpenChange={this.handleVisibilityChange}
                    options={filterConstants.textOptions}
                    table={table}
                />
                {
                    (visible || trueValue.length > 0) &&
                    <Col span={24}>
                        <Select
                            showSearch
                            placeholder={label?.toString()}
                            onChange={this.handleChange}
                            mode="tags"
                            style={{ width: "100%" }}
                            value={values?.map(v => v.value!).filter(v => v)}
                            tagRender={(props) => <Tag className={styles.tag} onClose={props.onClose} closeIcon={<Icon className={styles.tagClose} component={IoClose} />} closable>{props.label}</Tag>}
                        />
                    </Col>
                }
            </Row>
        );
    }
}

export default withTranslation(["ui"])(FilterListTextbox);