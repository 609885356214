// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxDropdown__NjqIg .ant-select-dropdown__i_v7A .ant-select-item-option-state___OglS {
  display: none;
}
.checkboxDropdown__NjqIg .ant-select-selection-overflow-item__sIw_c.ant-checkbox__X4QR1.ant-checkbox-checked__TajFE {
  display: none;
}
.padding__mhTvk {
  padding: 5px 12px;
}
.clearAll__mAX_F {
  padding: 0 12px;
}
.clearAll__mAX_F button {
  padding: 6px 0 2px;
  height: -moz-fit-content;
  height: fit-content;
}
.divider__v6yTe {
  padding: 0 12px;
}
.divider__v6yTe > div {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/checkbox-dropdown/styles/checkbox-dropdown-renderer.less"],"names":[],"mappings":"AAAA;EAEQ,aAAA;AAAR;AAFA;EAMQ,aAAA;AADR;AAKA;EACI,iBAAA;AAHJ;AAMA;EACI,eAAA;AAJJ;AAGA;EAGQ,kBAAA;EACA,wBAAA;EAAA,mBAAA;AAHR;AAOA;EACI,eAAA;AALJ;AAIA;EAIQ,SAAA;AALR","sourcesContent":[".checkboxDropdown {\n    .ant-select-dropdown .ant-select-item-option-state {\n        display: none;\n    }\n\n    .ant-select-selection-overflow-item.ant-checkbox.ant-checkbox-checked {\n        display: none;\n    }\n}\n\n.padding {\n    padding: 5px 12px;\n}\n\n.clearAll {\n    padding: 0 12px;\n    button {\n        padding: 6px 0 2px;\n        height: fit-content;\n    }\n}\n\n.divider {\n    padding: 0 12px;\n\n    >div {\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxDropdown": `checkboxDropdown__NjqIg`,
	"ant-select-dropdown": `ant-select-dropdown__i_v7A`,
	"ant-select-item-option-state": `ant-select-item-option-state___OglS`,
	"ant-select-selection-overflow-item": `ant-select-selection-overflow-item__sIw_c`,
	"ant-checkbox": `ant-checkbox__X4QR1`,
	"ant-checkbox-checked": `ant-checkbox-checked__TajFE`,
	"padding": `padding__mhTvk`,
	"clearAll": `clearAll__mAX_F`,
	"divider": `divider__v6yTe`
};
export default ___CSS_LOADER_EXPORT___;
