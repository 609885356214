import React from "react";
import { Typography } from "antd";

interface Props {
    ellipsis?: boolean;
    strong?: boolean;
    className?: string;
    value: string;
    highlight: string;
}

const HighlightText = ({ value, highlight, ...rest }: Props) => {
    return (
        <Typography.Text {...rest}>
            {
                highlight.length > 0 ?
                    value.split(new RegExp(`(${highlight})`, "gi"))
                        .map((part, index) => <Typography.Text key={index} mark={!!(index % 2)}>{part}</Typography.Text>)
                    :
                    value
            }
        </Typography.Text>
    );
}

export default HighlightText;
