import { ColDef, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { Button } from "antd";
import React, { Component, Key } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { EmptyRenderer } from "../empty";
import Grid, { getDefaultCellClassRules } from "../grid";
import styles from './styles/grid-selector.less';

interface Props<T> {
    initialValue?: Array<Key>;
    value?: Array<Key>;
    className?: string;
    unselectedTitle?: string;
    selectedEmpty?: string;
    unselectedEmpty?: string;
    selectedTitle?: string;
    addText: string;
    removeText: string;
    onGridData: (values: Array<Key>, selected: boolean) => T[];
    onGetKey: (value: T) => Key;
    onChange?: (values: Array<Key>) => void;
    onSelect?: (selected: T[]) => void;
    frameworkComponents?: any;
    columnDefs?: Array<ColDef>;
    unselectedComponent?: JSX.Element;
    disabled?: boolean;
    onDeselect?: (unselected?: T[], nodes?: T[]) => void;
    sortable?: boolean;
}

interface State {
    value: Array<Key>;
    unselectedGridOptions: GridOptions;
    selectedGridOptions: GridOptions;
    selectedCount: number;
    unselectedCount: number;
}

class GridSelector<T> extends Component<Props<T>, State> {
    unselectedGridApi?: GridApi;
    selectedGridApi?: GridApi;

    constructor(props: Props<T>) {
        super(props);

        const { unselectedEmpty, selectedEmpty, sortable = true } = this.props;

        let { frameworkComponents } = this.props;

        frameworkComponents.EmptyRenderer = EmptyRenderer;

        this.state = {
            value: props.value ?? props.initialValue ?? [],         
            unselectedGridOptions: {
                rowSelection: "multiple",
                groupSelectsChildren: true,
                suppressRowClickSelection: true,
                stopEditingWhenGridLosesFocus: true,
                columnDefs: props.columnDefs,
                defaultColDef: {
                    sortable,
                    unSortIcon: true,
                    filter: false,
                    flex: 1,
                    minWidth: 125,
                    resizable: true,
                    editable: false,
                    suppressMenu: true,
                    suppressMovable: true,
                    cellClassRules: getDefaultCellClassRules(),
                },
                noRowsOverlayComponent: "EmptyRenderer",
                noRowsOverlayComponentParams: {
                    description: unselectedEmpty
                },
                onSelectionChanged: this.handleDeselectCheckBoxChanged,
                onGridReady: this.handleUnselectedGridReady,
                frameworkComponents
            },
            selectedGridOptions: {
                rowSelection: "multiple",
                groupSelectsChildren: true,
                suppressRowClickSelection: true,
                stopEditingWhenGridLosesFocus: true,
                columnDefs: props.columnDefs,
                defaultColDef: {
                    sortable,
                    unSortIcon: true,
                    filter: false,
                    flex: 1,
                    minWidth: 125,
                    resizable: true,
                    editable: false,
                    suppressMenu: true,
                    suppressMovable: true,
                    cellClassRules: getDefaultCellClassRules(),
                },
                noRowsOverlayComponent: "EmptyRenderer",
                noRowsOverlayComponentParams: {
                    description: selectedEmpty
                },
                onSelectionChanged: this.handleSelectChanged,
                onGridReady: this.handleSelectedGridReady,
                frameworkComponents
            },
            selectedCount: 0,
            unselectedCount: 0
        }
    }

    componentDidUpdate = (prevProps: Props<T>) => {
        const { value } = this.props;

        if (value && value?.length !== prevProps?.value?.length) {
            this.setState({
                selectedCount: this.selectedGridApi?.getSelectedNodes().length ?? 0,
                unselectedCount: this.unselectedGridApi?.getSelectedNodes().length ?? 0
            });
        }
    }

    handleSelect = () => {
        const { onChange, onGetKey, onSelect } = this.props;
        const checkedUnselected = this.unselectedGridApi?.getSelectedRows() ?? [];
        const value: Key[] = this.props.value ?? this.state.value;

        if (onSelect) {
            onSelect(checkedUnselected);
        }
        else {
            while (checkedUnselected.length > 0) {
                const item = checkedUnselected.pop();

                if (item !== undefined && item.enabledCheckBox)
                    value.push(onGetKey(item));
            }


            this.setState({ value });

            if (onChange !== undefined) {
                onChange(value);
            }
        }
        this.unselectedGridApi?.deselectAll();
    }

    handleDeselect = () => {
        const { onChange, onGetKey, onDeselect } = this.props;
        const checkedSelected = this.selectedGridApi?.getSelectedRows() ?? [];
        const value: Key[] = this.props.value ?? this.state.value;

        if (onDeselect) {
            onDeselect(checkedSelected);
        }
        else {
            while (checkedSelected.length > 0) {
                const item = checkedSelected.pop();

                if (item !== undefined)
                    value.splice(value.indexOf(onGetKey(item)), 1);
            }

            this.setState({ value });

            if (onChange !== undefined) {
                onChange(value);
            }
        }
        this.selectedGridApi?.deselectAll();
    }

    handleSelectChanged = (e: SelectionChangedEvent) => {
        this.setState({ selectedCount : e.api.getSelectedNodes().length });
    }

    handleDeselectCheckBoxChanged = (e: SelectionChangedEvent) => {
        const { onDeselect } = this.props;
        if (onDeselect) {
            var nodes: T[] = e.api.getSelectedNodes().map((x: { data: T; }) => x.data);
            if (nodes) {
                onDeselect(undefined, nodes);
            } else {
                onDeselect(undefined, undefined);
            }

            e.api.refreshView();
        }
        this.setState({ unselectedCount: e.api.getSelectedNodes().length });
    }

    handleUnselectedGridReady = (params: GridReadyEvent) => {
        this.unselectedGridApi = params.api;
    }


    handleSelectedGridReady = (params: GridReadyEvent) => {
        this.selectedGridApi = params.api;
    }

    render() {
        const { className, unselectedTitle, selectedTitle, addText, removeText, onGridData, unselectedComponent, disabled } = this.props;
        const { unselectedGridOptions, selectedGridOptions, selectedCount, unselectedCount } = this.state;

        const value: Key[] = this.props.value ?? this.state.value;

        return (
            <div className={className + " " + styles.gridRow}>
                <div className={styles.gridCol}>
                    <h3 className={styles.subTitle + " " + (unselectedComponent ? styles.subTitleComponent : "")}>{unselectedTitle}{unselectedComponent ?? ""}</h3>
                    <div className={"ag-theme-balham " + styles.grid + " " + styles.gridAvailable}>
                        <Grid
                            gridOptions={unselectedGridOptions}
                            rowData={onGridData(value, false)}                        
                            />
                    </div>
                </div>
                <div className={styles.gridButtonsCol}>
                    <div>
                        <Button type="primary" disabled={unselectedCount === 0 || disabled} onClick={this.handleSelect}><MdArrowForward />{addText}</Button>
                        <Button danger={true} disabled={selectedCount === 0 || disabled} onClick={this.handleDeselect}><MdArrowBack />{removeText}</Button>
                    </div>
                </div>
                <div className={styles.gridCol}>
                    <h3 className={styles.subTitle}>{selectedTitle}</h3>
                    <div className={"ag-theme-balham " + styles.grid + " " + styles.gridAssigned}>
                        <Grid
                            gridOptions={selectedGridOptions}
                            rowData={onGridData(value, true)}
                            />
                    </div>
                </div>
            </div>
        );
    }
}

export default GridSelector;
