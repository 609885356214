import { Input } from "antd";
import { DeltaStatic, Sources } from "quill";
import { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// @ts-ignore
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
//import styles from "./styles/rich-text.less";
import { default as React } from "react";
import { InView } from "react-intersection-observer";
import RedoIcon from "./images/redo.svg";
import UndoIcon from "./images/undo.svg";

Quill.register("modules/emoji", Emoji);

interface Props {
    id?: string;
    className?: string;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    unmountOffScreen?: boolean;
}

/*
 * Class to render the image upload.
 */
export class RichText extends Component<Props> {
    constructor(props: Props) {
        super(props);

        const icons = Quill.import('ui/icons');

        icons['undo'] = '<img src="' + UndoIcon + '"/>';
        icons['redo'] = '<img src="' + RedoIcon + '"/>';
    }

    reactQuillRef = React.createRef<ReactQuill>();

    toolbarOptions = {
        container: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'clean'],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }, { 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link'],
            ['emoji'],
            ['undo'],
            ['redo']

        ],
        handlers: {
            'emoji': function () { },
            'undo': () => this.reactQuillRef.current?.getEditor().getModule("history").undo(),
            'redo': () => this.reactQuillRef.current?.getEditor().getModule("history").redo()
        },
    };

    hasContent = (htmlString: string) => {
        const parser = new DOMParser();
        const { textContent } = parser.parseFromString(htmlString, "text/html").documentElement;
        return (textContent?.trim().length ?? 0) > 0;
    }

    handleChange = (content: string) => {
        const { onChange } = this.props;

        if (onChange)
            if (this.hasContent(content))
                onChange(content);
            else
                onChange("");

    }

    handleEscape = () => {
        this.reactQuillRef.current?.blur();
    }

    render() {
        const { id, className, disabled, value, placeholder, unmountOffScreen } = this.props;
        const { toolbarOptions } = this;

        return (
            <InView skip={!unmountOffScreen}>
                {({ inView, ref }) => (
                    <div
                        id={id}
                        className={className}
                        ref={ref}
                    >
                        {
                            (!unmountOffScreen || inView) ? (
                                <ReactQuill
                                    ref={this.reactQuillRef}
                                    readOnly={disabled === true}
                                    theme="snow"
                                    placeholder={placeholder}
                                    modules={{
                                        toolbar: toolbarOptions,
                                        "emoji-toolbar": true,
                                        "emoji-textarea": false,
                                        "emoji-shortname": true,
                                        history: {
                                            userOnly: true
                                        },
                                        keyboard: {
                                            bindings: {
                                                escape: {
                                                    key: 27,
                                                    handler: this.handleEscape
                                                }
                                            }
                                        },
                                    }}
                                    value={value || ""}
                                    onChange={(content: string, _: DeltaStatic, source: Sources) => source === "user" && this.handleChange(content)}
                                />
                            ) : (
                                    <Input.TextArea disabled={disabled} rows={5} value={value} placeholder={placeholder} onChange={(e) => this.handleChange(e.target.value)} />
                            )}
                    </div>
                )}
            </InView>
        );
    }
}

export default (RichText);