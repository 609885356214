import React from "react";

export default function ContractedIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#FFECEA"/>
            <g clipPath="url(#clip0_1887_14702)">
                <rect width="16" height="16" transform="translate(4 4)" fill="white" fillOpacity="0.01"/>
                <path d="M8.88156 12.0071H9.7157C9.88632 12.0129 9.99059 12.0541 10.0285 12.1305C10.0601 12.1864 10.0759 12.2687 10.0759 12.3774V13.3474C10.0759 13.4415 10.0317 13.5312 9.94319 13.6164C9.67778 13.8721 9.27967 14 8.74885 14C8.27807 14 7.8689 13.8354 7.52134 13.5062C7.17378 13.174 7 12.7743 7 12.3069C7 11.8395 7.17694 11.45 7.53082 11.1384C7.8847 10.8239 8.30177 10.6667 8.78203 10.6667C9.15487 10.6667 9.50716 10.7813 9.83893 11.0106C9.92424 11.0694 9.96689 11.1355 9.96689 11.209C9.96689 11.2795 9.93056 11.3618 9.85788 11.4559C9.73466 11.6117 9.62249 11.6896 9.52138 11.6896C9.46135 11.6896 9.36498 11.6499 9.23228 11.5705C9.09957 11.4882 8.94159 11.4471 8.75833 11.4471C8.5182 11.4471 8.30493 11.5265 8.11851 11.6852C7.93209 11.841 7.83888 12.0467 7.83888 12.3025C7.83888 12.5553 7.93367 12.7713 8.12325 12.9506C8.31283 13.127 8.52768 13.2152 8.76781 13.2152C8.94159 13.2152 9.09799 13.1887 9.23702 13.1358V12.6552H8.8626C8.76465 12.6552 8.69514 12.6434 8.65407 12.6199C8.61299 12.5964 8.58613 12.5626 8.57349 12.5185C8.56086 12.4715 8.55454 12.4098 8.55454 12.3333C8.55454 12.254 8.56086 12.1908 8.57349 12.1437C8.58929 12.0967 8.61615 12.0644 8.65407 12.0467C8.71094 12.0203 8.78677 12.0071 8.88156 12.0071Z" fill="#FF3216"/>
                <path d="M11.3502 10.7196L12.227 10.724C12.6851 10.724 13.088 10.8783 13.4355 11.187C13.7831 11.4927 13.9569 11.8704 13.9569 12.3201C13.9569 12.7669 13.7863 13.1505 13.445 13.4709C13.1069 13.7913 12.6962 13.9515 12.2128 13.9515H11.3454C11.1527 13.9515 11.0326 13.9148 10.9852 13.8413C10.9473 13.7795 10.9284 13.6855 10.9284 13.5591V11.1076C10.9284 11.037 10.9299 10.9856 10.9331 10.9533C10.9394 10.9209 10.9552 10.8827 10.9805 10.8386C11.0247 10.7593 11.148 10.7196 11.3502 10.7196ZM12.227 13.1711C12.4545 13.1711 12.6598 13.0932 12.8431 12.9374C13.0264 12.7787 13.118 12.5802 13.118 12.3422C13.118 12.1041 13.0279 11.9056 12.8478 11.7469C12.6709 11.5852 12.4624 11.5044 12.2222 11.5044H11.7672V13.1711H12.227Z" fill="#FF3216"/>
                <path d="M14.5971 13.5018C14.515 13.4077 14.4739 13.3239 14.4739 13.2504C14.4739 13.174 14.5418 13.0741 14.6777 12.9506C14.7567 12.8801 14.8404 12.8448 14.9289 12.8448C15.0173 12.8448 15.1406 12.9183 15.2985 13.0653C15.3428 13.1152 15.406 13.1637 15.4881 13.2108C15.5703 13.2549 15.6461 13.2769 15.7156 13.2769C16.0095 13.2769 16.1564 13.1652 16.1564 12.9418C16.1564 12.8742 16.1153 12.8183 16.0332 12.7743C15.9542 12.7272 15.8546 12.6934 15.7346 12.6728C15.6145 12.6523 15.485 12.6199 15.3459 12.5758C15.2069 12.5288 15.0774 12.4744 14.9573 12.4127C14.8372 12.351 14.7361 12.254 14.654 12.1217C14.575 11.9865 14.5355 11.8248 14.5355 11.6367C14.5355 11.378 14.6382 11.1531 14.8436 10.9621C15.0521 10.7681 15.3349 10.6711 15.6919 10.6711C15.8815 10.6711 16.0537 10.6946 16.2085 10.7416C16.3665 10.7857 16.4755 10.8313 16.5355 10.8783L16.654 10.9621C16.752 11.0473 16.8009 11.1193 16.8009 11.1781C16.8009 11.2369 16.763 11.3178 16.6872 11.4206C16.5798 11.5676 16.4692 11.6411 16.3554 11.6411C16.2891 11.6411 16.2069 11.6117 16.109 11.5529C16.0995 11.547 16.0805 11.5323 16.0521 11.5088C16.0268 11.4853 16.0031 11.4662 15.981 11.4515C15.9147 11.4133 15.8294 11.3942 15.7251 11.3942C15.624 11.3942 15.5387 11.4177 15.4692 11.4647C15.4028 11.5088 15.3696 11.572 15.3696 11.6543C15.3696 11.7337 15.4091 11.7984 15.4881 11.8483C15.5703 11.8983 15.6714 11.9321 15.7914 11.9497C15.9115 11.9674 16.0426 11.9953 16.1848 12.0335C16.327 12.0688 16.4581 12.1129 16.5782 12.1658C16.6983 12.2187 16.7978 12.3113 16.8768 12.4436C16.9589 12.5729 17 12.7331 17 12.9242C17 13.1152 16.9589 13.2842 16.8768 13.4312C16.7946 13.5753 16.6872 13.687 16.5545 13.7663C16.2986 13.9221 16.0253 14 15.7346 14C15.5861 14 15.4455 13.9838 15.3128 13.9515C15.1801 13.9162 15.0726 13.8736 14.9905 13.8236C14.823 13.7296 14.7061 13.6384 14.6398 13.5503L14.5971 13.5018Z" fill="#FF3216"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M15.7213 6.61208H8.2788C7.35833 6.61208 6.61214 7.35827 6.61214 8.27875V15.7213C6.61214 16.6417 7.35833 17.3879 8.2788 17.3879H15.7213C16.6418 17.3879 17.388 16.6417 17.388 15.7213V8.27875C17.388 7.35827 16.6418 6.61208 15.7213 6.61208ZM8.2788 5.61208C6.80604 5.61208 5.61214 6.80599 5.61214 8.27875V15.7213C5.61214 17.194 6.80604 18.3879 8.2788 18.3879H15.7213C17.1941 18.3879 18.388 17.194 18.388 15.7213V8.27875C18.388 6.80599 17.1941 5.61208 15.7213 5.61208H8.2788Z" fill="#FF3216"/>
            </g>
            <defs>
                <clipPath id="clip0_1887_14702">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
