import AppStorage from "./storage";

/**
 * Class to interact with local storage
 */
class LocalAppStorage extends AppStorage {

    /**
     * Retrieves object stored in local storage (json format), load into memory
     */
    public load(): void {
        let storageJson = localStorage.getItem(this.storageKey);
        if (storageJson == null) {
            this.jsonObject = {};

            return;
        }

        this.jsonObject = JSON.parse(storageJson) as any;
    }

    /**
     * Save information to local storage as json
     */
    public save(): void {
        localStorage.setItem(this.storageKey, JSON.stringify(this.jsonObject));
    }
}

const localAppStorage = new LocalAppStorage();

export default localAppStorage;