import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localieFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import tz from 'dayjs/plugin/timezone';

require('dayjs/locale/en');
require('dayjs/locale/es');
require('dayjs/locale/fr');

//Add DayJS extensions.
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localieFormat);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(tz);

export { dayjs };
