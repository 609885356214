// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label.inputLabel__AoEwd {
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
}
label.inputLabel__AoEwd.required__u4wb9:after {
  content: "*";
  position: absolute;
  top: -0.25em;
  right: -0.75em;
  color: #FF4D4F;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/input-label/styles/input-label.module.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,0BAAA;AADJ;AAIQ;EACI,YAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;AAFZ","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\nlabel.inputLabel {\n    position: relative;\n    display: inline-block;\n    font-weight: 500;\n    font-size: 14px;\n    margin-bottom: 4px;\n    color: @textColor;\n\n    &.required {\n        &:after {\n            content: \"*\";\n            position: absolute;\n            top: -.25em;\n            right: -.75em;\n            color: @errorColor;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `inputLabel__AoEwd`,
	"required": `required__u4wb9`
};
export default ___CSS_LOADER_EXPORT___;
