import { Affix } from 'antd';
import React, { Component } from 'react';
import { localAppStorage, withApp, WithApp } from 'state';
import { HelpScout } from 'third-party';
import { UserModel } from 'user';
import ClientHeader from './client';
import EventHeader from './event';
import ClientMobile from './mobile/client';
import EventMobile from './mobile/event';
import SystemMessageList from './system-messages/system-message-list';
import TestSiteWarning from './test-site-warning';
import appInsights from './utility/application-insights';
import hotJar from './utility/hot-jar';
import flywheel from './utility/flywheel';
import notificationListener, { NotificationListener } from './utility/notification-listener';

const BANNER_CLOSED_COOKIE = "bannerClosed";
const POPUP_CLOSED_COOKIE = "popupClosed";

interface Props extends WithApp {
    user: UserModel;
    refreshUser: () => Promise<void>;
}

interface State {
    refreshTime?: string;
    hasSystemMessages: boolean;
    isTestSite: boolean;
}

class Menu extends Component<Props, State> {
    notificationListener: NotificationListener;

    constructor(props: Props) {
        super(props);

        this.state = {
            hasSystemMessages: false,
            isTestSite: (document.getElementById("test-warning") as HTMLInputElement)?.value.toLowerCase() === "true",
        };

        this.notificationListener = notificationListener;
        this.notificationListener.init(this.props.refreshUser, props.app.notification);
    }

    componentDidUpdate = () => {
        const { user } = this.props;

        if (user !== null) {
            if (user.keys.telemetryKey !== null && user.keys.telemetryKey !== '') {
                appInsights.init(user.keys.telemetryKey);
            }

            if (user.keys.hotJarSettings !== null && user.keys.hotJarSettings !== '') {
                hotJar.init(user.keys.hotJarSettings);
            }

            if (user.isLoggedIn && user.keys.flywheelKey !== null && user.keys.flywheelKey !== '') {
                flywheel.init(user.keys.flywheelKey, user);
            }
        }
    }

    refreshSystemMessages = () => {
        localAppStorage.set(BANNER_CLOSED_COOKIE, undefined).save();
        localAppStorage.set(POPUP_CLOSED_COOKIE, undefined).save();
        this.setState({ refreshTime: new Date().toISOString() });
    }

    handleSetHasSystemMessages = (hasMessage: boolean) => {
        this.setState({ hasSystemMessages: hasMessage });
    }

    render() {
        const { user, refreshUser, breakpoints } = this.props;
        const { refreshTime, hasSystemMessages, isTestSite } = this.state;

        return (
            <Affix offsetTop={0}>
                <div>
                    {
                        breakpoints.lg ?
                            <>
                                <SystemMessageList user={user} refreshTime={refreshTime} onSetHasSystemMessage={this.handleSetHasSystemMessages} />
                                <ClientHeader user={user} refreshUser={refreshUser} refreshSystemMessages={this.refreshSystemMessages} hasSystemMessages={hasSystemMessages} />
                                <EventHeader user={user} isTestSite={isTestSite} />
                            </>
                            :
                            <>
                                <SystemMessageList user={user} refreshTime={refreshTime} onSetHasSystemMessage={this.handleSetHasSystemMessages} />
                                <ClientMobile user={user} refreshUser={refreshUser} refreshSystemMessages={this.refreshSystemMessages} hasSystemMessages={hasSystemMessages} />
                                <EventMobile user={user} isTestSite={isTestSite} />
                            </>
                    }

                    <TestSiteWarning user={user} isTestSite={isTestSite} />
                    <HelpScout user={user} />
                </div>
            </Affix>
        );
    }
}

export default withApp(Menu);