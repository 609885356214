import { Component } from "react";
import styles from "./styles/test-status-bar.less";
import { WithTranslation, withTranslation } from "react-i18next";
import clsx from "clsx";
import React from "react";

interface Props extends WithTranslation {
}

class TestStatusBar extends Component<Props> {
    render() {
        const { t } = this.props;
        return (
            <div className={clsx(styles.testStatusBar)}>
                { t("testingSite") }
            </div>
        )
    }
}

export default withTranslation(["menu"])(TestStatusBar);
