// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateCarousel__amwTo .slick-list .slick-track .slick-slide {
  align-self: flex-start;
}
.dateCarousel__amwTo .slick-disabled {
  opacity: 0.25;
}
.dateCarousel__amwTo .slick-arrow {
  color: rgba(0, 0, 0, 0.65);
}
.dateCarousel__amwTo .slick-arrow:hover,
.dateCarousel__amwTo .slick-arrow:focus {
  color: rgba(0, 0, 0, 0.65);
}
.dateCarousel__amwTo .slick-slide > div > div {
  display: flex!important;
  justify-content: center;
}
.dateCard__ed3i8 {
  background-color: #DDDDDD;
  height: 70px;
  width: 70px;
  border-radius: 8px;
  border: none;
}
.dateCard__ed3i8 > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
  height: 100%;
}
.dateCard__ed3i8 > div > span {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 4px;
}
.dateCard__ed3i8 > div > svg {
  height: 24px;
  width: 24px;
}
.dateCard__ed3i8.reserved__PVHHU {
  background-color: #DBFDFF;
}
.dateCard__ed3i8.reserved__PVHHU > div > svg {
  color: #21B0B7;
}
.dateCard__ed3i8.waitlist__hl2Pp {
  background-color: #FFEBBF;
}
.dateCard__ed3i8.waitlist__hl2Pp > div > svg {
  color: #F5BC41;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/menu/dropdowns/styles/booking-night-grid.less"],"names":[],"mappings":"AAEA;EAGY,sBAAA;AAHZ;AAAA;EAMY,aAAA;AAHZ;AAHA;EASY,0BAAA;AAHZ;AAIY;;EACI,0BAAA;AADhB;AAVA;EAeY,uBAAA;EACA,uBAAA;AAFZ;AAOA;EACI,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AALJ;AAAA;EAOQ,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,wBAAA;EACA,YAAA;AAJR;AARA;EAcY,gBAAA;EACA,eAAA;EACA,kBAAA;AAHZ;AAbA;EAmBY,YAAA;EACA,WAAA;AAHZ;AAOI;EACI,yBAAA;AALR;AAII;EAGQ,cAAA;AAJZ;AAOI;EACI,yBAAA;AALR;AAII;EAGQ,cAAA;AAJZ","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.dateCarousel {\n    :global {\n        .slick-list .slick-track .slick-slide {\n            align-self: flex-start;\n        }\n        .slick-disabled {\n            opacity: 0.25;\n        }\n        .slick-arrow {\n            color: @textColor;\n            &:hover, &:focus {\n                color: @textColor;\n            }\n        }\n        .slick-slide > div > div {\n            display: flex!important;\n            justify-content: center;\n        }\n    }\n}\n\n.dateCard {\n    background-color: @grayThree;\n    height: 70px;\n    width: 70px;\n    border-radius: 8px;\n    border: none;\n    > div {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        padding: 10px !important;\n        height: 100%;\n        > span {\n            font-weight: 700;\n            font-size: 13px;\n            margin-bottom: 4px;\n        }\n        > svg {\n            height: 24px;\n            width: 24px;\n        }\n    }\n\n    &.reserved {\n        background-color: @apolloColorLight;\n        > div > svg {\n            color: @apolloColorNeon;\n        }\n    }\n    &.waitlist {\n        background-color: @warningColorLight;\n        > div > svg {\n            color: @warningColor;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateCarousel": `dateCarousel__amwTo`,
	"dateCard": `dateCard__ed3i8`,
	"reserved": `reserved__PVHHU`,
	"waitlist": `waitlist__hl2Pp`
};
export default ___CSS_LOADER_EXPORT___;
