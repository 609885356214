import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react";
import { LabeledValue } from "antd/es/select";
import { WithTranslation, withTranslation } from "react-i18next";
import { Tag } from "antd";

// to ensure styling is the same as the search select editor
import styles from '../search-select/styles/search-select-renderer.less';

interface Props extends ICellRendererParams, WithTranslation {
    showAll: boolean | undefined;
}

interface State {
    value: LabeledValue | LabeledValue[];
    placeholderText: string | undefined;
}

class SelectRenderer extends Component<Props, State> {  

    constructor(props: Props) {
        super(props);
        const { t } = this.props;
        const databaseField = props.data?.databaseField;
        let placeholderText;
        if (databaseField && Array.isArray(databaseField) && databaseField.length == 1) {
            placeholderText = t("selectFormat");
        }

        this.state = {
            value: props.value,
            placeholderText: placeholderText,
        }
    }

    render() {
        const { value, placeholderText } = this.state;
        const { t, showAll } = this.props;

        if (value !== undefined && value !== null) {
            if (Array.isArray(value)) {
                if (value.length > 0 && !(value.length === 1 && value[0].value == "default")) {
                    if (showAll === true) {
                        return <span className={styles.select}>{value.map((val) => { return val.label }).join("; ")}</span>;
                    } else {
                        return <span className={styles.select}>
                            {value[0].label}
                            {(value.length > 1) ? <Tag className={styles.selectTag}>{t("plusnMore", { n: value.length - 1})}</Tag> : ""}
                        </span>;
                    }
                }
            } else if (value.value !== "default") {
                return <span className={styles.select}>{value.label}</span>
            }
        }

        return <span className={styles.select + " " + styles.placeholder}>{placeholderText ?? t("select")}</span>;
    }

}

export default withTranslation(["ui"])(SelectRenderer);
