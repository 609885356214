import { Button, Col, DatePicker, Drawer, Form, FormInstance, Input, Row } from "antd";
import { Store } from "antd/es/form/interface";
import { dayjs, Dayjs } from "translations";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { HistoryWrapper, SearchSelect } from "ui";
import { UserModel } from "user";
import { EventGetModel, serviceClient } from "web-service";
import { withApp, WithApp } from "state";

interface Props extends WithTranslation, WithApp {
    user: UserModel;
    open: boolean;
    onClose: () => void;
}

interface State {
    minStartDate: Dayjs;
    minEndDate: Dayjs;
    endDateOpen: boolean;    
    counter: number;
    loading: boolean;
}

class CloneEventDrawer extends Component<Props, State> {
    formRef = React.createRef<FormInstance>();
    constructor(props: Props) {
        super(props);

        this.state = {
            minStartDate: dayjs.utc().startOf("day"),
            minEndDate: dayjs.utc().startOf("day").add(4, "days"),
            endDateOpen: false,
            counter: 0,
            loading: false
        };
    }

    disabledStartDate = (startValue: Dayjs) => {
        const adjustedStartValue = dayjs.utc(startValue).startOf('day');
        const currentDate = dayjs.utc().startOf('day');
        return adjustedStartValue < currentDate;
    }

    disabledEndDate = (endValue: Dayjs) => {
        const adjustedEndValue = dayjs.utc(endValue).startOf('day');
        const adjustedStartValue = this.state.minStartDate?.utc().startOf('day');
        if (!adjustedEndValue || !adjustedStartValue) {
            return false;
        }
        return adjustedEndValue < adjustedStartValue;
    }

    handleStartChanged = (value: Dayjs | null) => {
        const { minStartDate, minEndDate } = this.state;
        if (minEndDate && minStartDate && value) {
            if (minEndDate < value) {
                this.setState({ minStartDate: value, minEndDate: value });
                this.formRef.current?.setFieldsValue({ endDate: value });
            }
            else {
                this.setState({
                    minStartDate: value
                });
            }
        }
    }

    handleEndChanged = (value: Dayjs | null) => {
        if (value) {
            this.setState({ minEndDate: value });
        }
    }

    handleStartOpenChanged = (open: boolean) => {
        if (!open) {
            this.setState({ endDateOpen: true });
        }
    }

    handleEndOpenChanged = (open: boolean) => {
        this.setState({ endDateOpen: open });
    }

    handleStartDateChanged = (date: Dayjs) => {
        this.setState({ minEndDate: date });
    }

    handleFinish = async (values: Store) => {
        const { t, user, app: { notification } } = this.props;
        this.setState({ loading: true });

        const fields = await this.formRef.current?.validateFields().catch(() => undefined);

        if (fields === undefined) {
            const validation = this.formRef.current?.getFieldsError();

            return t("genericEventValidation");
        } else {
            serviceClient.post("Clone", "Event", {
                eventID: values.event?.value,
                clientID: values.client.value,
                name: values.eventName,
                startDate: values.startDate,
                endDate: values.endDate
            })
                .promise
                .then((cloneEvent: EventGetModel) => {
                    this.setState({ loading: false }, () => {
                        const url = user.baseUrl + '/Event/Set/' + cloneEvent.id;
                        HistoryWrapper.pushState("Event", '', url);
                        HistoryWrapper.go();
                    });
                })
                .catch(() => {
                    this.setState({ loading: false });
                    notification.error({
                        message: t('shared:error'),
                        description: t('shared:genericError')
                    });
                });
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        const { loading } = this.state;

        if (!loading)
            onClose();
    }

    render() {
        const { t, open, user, breakpoints } = this.props;
        const { loading, minStartDate, endDateOpen } = this.state;

        return (
            <Drawer
                title={t("cloneEvent")}
                footer={loading ? null : undefined}
                open={open}
                placement={breakpoints.lg ? "right" : "left"}
                width={breakpoints.lg ? 500: "95%"}
                onClose={this.handleClose}
            >
                <p>{t("cloneEventDescription")}</p>
                <Form
                    scrollToFirstError={true}
                    onFinish={this.handleFinish}
                    layout="vertical"
                    ref={this.formRef}
                    initialValues={{
                        event: user.event == null ? null : user.event.id
                    }}
                >
                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                name="event"
                                label={t("eventToClone")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("event") })
                                    }
                                ]}>
                                <SearchSelect
                                    disabled={loading}
                                    action="Select"
                                    controller="Event"
                                    showSearch={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="client"
                                label={t("client")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("client") })
                                    }
                                ]}
                            >
                                <SearchSelect
                                    disabled={loading}
                                    action="Select"
                                    controller="Client"
                                    showSearch={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="eventName"
                                label={t("newEventName")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("newEventName") })
                                    },
                                    { max: 255, message: t("shared:validationMaxCharacters", { max: 255 }) },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row wrap justify="space-between">
                                <Col xs={24} sm={11}>
                                    <Form.Item
                                        name="startDate"
                                        label={t("startDate")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("shared:validationRequired", { field: t("startDate") })
                                            }
                                        ]}
                                    >
                                        <DatePicker
                                            disabledDate={this.disabledStartDate}
                                            onChange={this.handleStartChanged}
                                            onOpenChange={this.handleStartOpenChanged}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={11}>
                                    <Form.Item
                                        name="endDate"
                                        label={t("endDate")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("shared:validationRequired", { field: t("endDate") })
                                            },
                                            {
                                                required: true,
                                                validator: (_, value) =>
                                                    minStartDate !== undefined ?
                                                        !dayjs.utc(value).isBefore(minStartDate) ? Promise.resolve() :
                                                            Promise.reject(t("endDateRequirementFailed"))
                                                        : value ? Promise.resolve() : Promise.reject(t("shared:validationRequired", { field: t("endDate") }))
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            disabledDate={this.disabledEndDate}
                                            value={minStartDate}
                                            onChange={this.handleEndChanged}
                                            open={endDateOpen}
                                            onOpenChange={this.handleEndOpenChanged}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {t("create")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        );
    }
}

export default withApp(withTranslation(["menu", "shared"])(CloneEventDrawer));