import { Button, Col, Popover, Row } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MdLaunch } from 'react-icons/md';
import { permissionLookup } from 'security';
import { UserModel } from 'user';
import status from './data/status';
import { LinkPane, ManagePane, ReportPane } from "./dropdowns";
import styles from "./styles/event.less";
import clsx from "clsx";

interface Props extends WithTranslation {
    user: UserModel;
    isTestSite: boolean;
}

interface State {
    linksPopover: boolean;
    reportsPopover: boolean;
    managePopover: boolean;
}

class EventHeader extends Component<Props, State> {
    constructor(props : Props) {
        super(props);

        this.state = {
            linksPopover: false,
            reportsPopover: false,
            managePopover: false
        }
    }

    render() {
        const { t, user, isTestSite } = this.props;
        const { linksPopover, reportsPopover, managePopover } = this.state;

        return (
            user.event != undefined
                ? (
                    <header
                        className={clsx(styles.headerEvent, {
                            [styles.removeBorderRadius]: isTestSite
                        })}
                        style={{ backgroundColor: user.styles.HEADERMENUHEX, color: user.styles.HEADERMENUTEXTHEX }}
                    >
                        <Row align="middle" justify="space-between" className={styles.rowMenu}>
                            <Col className={clsx(styles.colStatus, status.getStatusClass(user.event.status, styles))}>
                                <a href={user.baseUrl + "/Event/Status"}>{status.getStatus(user.event.status, t)}</a>
                            </Col>
                            <Col className={styles.colEventName}>
                                <Popover
                                    getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement}
                                    content={<LinkPane user={user}/>}
                                    trigger="click"
                                    placement="bottomLeft"
                                    overlayClassName={clsx(styles.popoverNav, styles.popoverLink)}
                                    onOpenChange={(open) => {
                                        this.setState({ linksPopover: open });
                                    }}
                                >
                                    <Button
                                        type="link"
                                        className={clsx(styles.navItem, styles.navItemEvent, styles.navItemPop, linksPopover && styles.open)}
                                        style={{ color: user.styles.HEADERMENUTEXTHEX }}
                                    >
                                        {user.event.name} <MdLaunch/>
                                    </Button>
                                </Popover>
                            </Col>
                            <Col className={styles.colMenuItems}>
                                <Button
                                    type="link"
                                    className={clsx(styles.navItem, styles.navItemEvent)}
                                    style={{ color: user.styles.HEADERMENUTEXTHEX }}
                                    href={user.baseUrl + "/Dashboard"}
                                >
                                    {t("dashboard")}
                                </Button>

                                {
                                    user.event.eventPermissions.includes(permissionLookup.RESERVATION_VIEW) &&
                                    <Button
                                        type="link"
                                        className={clsx(styles.navItem, styles.navItemEvent)}
                                        style={{ color: user.styles.HEADERMENUTEXTHEX }}
                                        href={user.baseUrl + "/Booking/Search"}>
                                        {t("eventReservations")}
                                    </Button>
                                }

                                {
                                    user.event.eventPermissions.includes(permissionLookup.REPORT_PLANNER) &&
                                    <Popover
                                        getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement}
                                        content={<ReportPane user={user} categories={true}/>}
                                        trigger="click"
                                        placement="bottomRight"
                                        overlayClassName={styles.popoverNav}
                                        onOpenChange={(open) => {
                                            this.setState({ reportsPopover: open });
                                        }}
                                        overlayInnerStyle={{ width: 770 }}
                                    >
                                        <Button
                                            type="link"
                                            className={clsx(styles.navItem, styles.navItemEvent, styles.navItemPop, reportsPopover && styles.open)}
                                            style={{ color: user.styles.HEADERMENUTEXTHEX }}
                                        >
                                            {t("reports")}
                                        </Button>
                                    </Popover>
                                }

                                {
                                    user.event.eventPermissions.includes(permissionLookup.EVENT_MANAGEMENT) &&
                                    <Popover
                                        getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement}
                                        content={<ManagePane user={user}/>}
                                        trigger="click"
                                        placement="bottomRight"
                                        overlayClassName={styles.popoverNav}
                                        onOpenChange={(open) => {
                                            this.setState({ managePopover: open });
                                        }}
                                        overlayInnerStyle={{ width: 410 }}
                                    >
                                        <Button
                                            type="link"
                                            className={clsx(styles.navItem, styles.navItemEvent, styles.navItemPop, managePopover && styles.open)}
                                            style={{ color: user.styles.HEADERMENUTEXTHEX }}
                                        >
                                            {t("manage")}
                                        </Button>
                                    </Popover>
                                }
                            </Col>
                        </Row>
                    </header>
                )
                :
                <span/>
        );
    }
}

export default withTranslation('menu')(EventHeader);
